import store from "@/store";
import { modals, toggleShow } from "@/utils/modals";
import { getCookie, deleteCookie } from "@/utils/cookie";
import { fn } from "@/views/login/login.js";
import { decryptUrlData } from "@/utils/encrypt";

const launchFilter = async (to, from, next) => {
  let isInvalid = false;
  const link = document.querySelector("[rel='icon']");
  link.setAttribute("href", to.meta.icon);
  document.title = to.meta.title;
  if (to.query.token) {
    deleteCookie("CID");
    store.commit("login/initLoginUser");
    let userInfo = JSON.parse(decryptUrlData(to.query.token));
    if (!userInfo) {
      throw "Unknown User Infomation";
    } else {
      localStorage.token = "token";
      isInvalid = true;
      await fn.login({
        userId: userInfo.user_id,
        pass: userInfo.password,
        token: to.query.token,
      });
    }
  }
  if (isInvalid) next("/out-game");
  else next();
};

export { launchFilter };
