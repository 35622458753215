<template>
  <div class="loginWrap">
    <div class="contentWrap">
      <div class="longWrap">
        <div class="infoBoxLong">
          <label class="title_input">ID</label>
          <div class="input-form">
            <component class="icon" :is="username" />
            <input placeholder="ID" v-model="dataModel.userId" />
          </div>
        </div>
        <div class="infoBoxLong">
          <label class="title_input">email</label>
          <div class="input-form">
            <component class="icon" :is="email" />
            <input type="email" placeholder="Email" />
          </div>
        </div>
        <div class="infoBoxLong">
          <label class="title_input">password</label>
          <div class="input-form">
            <component class="icon" :is="password" />
            <input
              :type="isShowPwd ? 'text' : 'password'"
              placeholder="Password"
              v-model="dataModel.pass"
            />
            <component
              v-if="isShowPwd"
              class="icon-eye"
              :is="uneye"
              @click.stop="isShowPwd = !isShowPwd"
            />
            <component
              v-else
              class="icon-eye"
              :is="eye"
              @click.stop="isShowPwd = !isShowPwd"
            />
          </div>
        </div>
      </div>
      <div class="formWrap3">
        <button class="btn1" @click.stop="login(), (musicType = 'click')">
          {{ t("createAccount") }}
        </button>
        <div>
          <span>{{ t("alreadyMember") }}</span
          ><span
            class="yellow"
            style="color: #dac16e"
            @click.stop="toggleShow('signup'), (musicType = 'click')"
            >{{ t("login") }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <Music :musicType="musicType" />
</template>
<script setup>
import { ref, reactive, watch } from "vue";
import { fn } from "@/views/login/login.js";
import { useI18n } from "vue-i18n";
import { toggleShow } from "@/utils/modals";
import { useStore } from "vuex";
import Music from "@/components/music/Music";
import { username, password, eye, uneye, email } from "@/assets/svg/login";

const { t } = useI18n();
const store = useStore();

const dataModel = reactive({
  userId: "",
  pass: "",
});

const isShowPwd = ref(false);
const musicType = ref(false);

const login = async () => {
  const res = await fn.login(dataModel);
  if (res.data.is_success) {
    toggleShow("signup");
    toggleShow("login");
  }
  if (res.data.Message.indexOf("비밀번호") > -1) {
    store.commit("setErr", "이미 존재하는 계정입니다.");
  }
};

watch(
  () => musicType.value,
  (val) => {
    if (val) {
      setTimeout(() => {
        musicType.value = undefined;
      }, 100);
    }
  }
);
</script>
<style scoped>
.loginWrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginWrap .contentWrap .shortWrap {
  display: flex;
  width: inherit;
  height: 100px;
  align-items: center;
  gap: 12px;
}
.loginWrap .contentWrap .longWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: max-content;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 12px;
  }
}
.loginWrap .contentWrap .formWrap3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 12px;
  }
}

.loginWrap .contentWrap {
  max-width: 330px;
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 12px;
  }
}

.infoBoxShort {
  width: 180px;
  height: 48px;
  display: flex;
  align-items: center;
  border: 1px solid #c0a260;
  background-color: #000000;
  border-radius: 6px;
}

.infoBoxShort input {
  width: inherit;
}

.infoBoxLong {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.infoBoxLong .title_input {
  color: #fff;
  font-size: 18px;
  text-transform: capitalize;
}

.infoBoxLong .input-form {
  height: 50px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0px 0px 0px 10px;
  border-radius: 12px;
  border: 0.304px solid #fff;
  background: #dedede;
  backdrop-filter: blur(6.0781121253967285px);

  @media (max-width: 768px) {
    gap: 12px;
    backdrop-filter: none;
  }

  @media (max-width: 500px) and (max-height: 500px) {
    height: 40px;
  }

  .icon-eye {
    margin-right: 4px;
    width: 25px;
    cursor: pointer;
  }
}

.infoBoxLong .input-form input {
  width: 100%;
  height: 100%;
  color: #202020;
  font-size: 18px;
}

.infoBoxLong .input-form input::placeholder {
  color: #797979;
}

button {
  font-size: 22px;
  letter-spacing: 0.56px;
  color: #c0a260;
  width: 100%;
  height: 50px;
  border: 1px solid transparent;
  background-clip: content-box;
  background-size: 100% 100%;
  border-radius: 10px;

  @media (max-width: 500px) and (max-height: 500px) {
    height: 40px;
  }
}

button.btn1 {
  background: transparent;
  border: 1px solid #dac16e;
  font-size: 20px;
  color: #fff;
  transition: all 0.3s ease-in-out;

  &:hover {
    transition: all 0.3s ease-in-out;
    background: linear-gradient(
      180deg,
      #dac16e 0%,
      #f2e79b 20%,
      #fffec0 40%,
      #f0e7a1 60%,
      #dcc670 73.5%,
      #e4cf7d 100%
    );
    color: #202020;
    opacity: 1;
  }
}

.formWrap3 div {
  display: flex;
  gap: 10px;
}

.formWrap3 div span:last-child {
  cursor: pointer;
}
</style>
