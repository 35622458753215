import { createStore } from 'vuex'
import request from '@/store/modules/request';
import login from '@/store/modules/login';
import round from '@/store/modules/round';
import history from '@/store/modules/history';
import cms from '@/store/modules/cms';

/*import createPersistedState from "vuex-persistedstate";*/

export default createStore({
    state: {
        err: undefined,
        selectRoomNum: -1,
        selectedCardIdx: -1,
        selectedCardSlot: -1,
        selectedPlayerSlot: -1,
        selectedCardType: "",
        onCardPopup: false,
    },
    mutations: {
        setErr: (state, payload) => {
            state.err = payload
            setTimeout(() => {
                state.err = undefined
            }, 3000);
        },
        setSelectRoomNum: (state, payload) => {
            state.selectRoomNum = payload
        },
        setSelectCardIdx: (state, payload) => {
            state.selectedCardIdx = payload
        },
        setOnCardPopup: (state, payload) => {
            state.onCardPopup = payload
        },
        setSelectCardType: (state, payload) => {
            state.selectedCardType = payload
        },
        setSelectPlayerSlot: (state, payload) => {
            state.selectedPlayerSlot = payload
        },
        setSelectCardSlot: (state, payload) => {
            state.selectedCardSlot = payload
        },
    },
    getters: {
        getErr: (state) => {
            return state.err;
        },
        getSelectRoomNum: (state) => {
            return state.selectRoomNum;
        },
        getSelectCardIdx: (state) => {
            return state.selectedCardIdx;
        },
        getOnCardPopup: (state) => {
            return state.onCardPopup;
        },
        getSelectCardType: (state) => {
            return state.selectedCardType;
        },
        getSelectPlayerSlot: (state) => {
            return state.selectedPlayerSlot;
        },
        getSelectCardSlot: (state) => {
            return state.selectedCardSlot;
        }
    },
    actions: {
    },
    modules: {
        request, login, round, history, cms
    },
/*    plugins: [createPersistedState()]*/
})
