<template>
  <div class="myInfoWrap" :class="{ layoutType2: layoutType == 1 }">
    <div class="wrap_avatar">
      <div class="avatar">
        <img loading="lazy"
             :src="require(`@/assets/avatar/${iconNum}.png`)"/>
        <div class="action_choose_image" @click="toggleShow('avatar')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 54 54"
            fill="none"
          >
            <path
              d="M27 49.5C39.375 49.5 49.5 39.375 49.5 27C49.5 14.625 39.375 4.5 27 4.5C14.625 4.5 4.5 14.625 4.5 27C4.5 39.375 14.625 49.5 27 49.5Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M18 27H36"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M27 36V18"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div class="valueInfo">{{ loginUser?.["userId"] }}</div>
    </div>
    <div class="infoWrap" v-for="(info, idx) in cols" :key="idx">
      <div class="label">
        {{ t(info.key) }}
      </div>
      <div
        class="info"
        v-if="info['type'] == 'number'"
        v-numberFormat="loginUser?.[info.val]"
      ></div>
      <div class="info" v-else-if="info['type'] == 'image'">
        <img :src="getFlagImage(loginUser?.[info.val])" />
      </div>
      <div class="info" v-else>
        {{ loginUser?.[info.val] }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { toggleShow } from "@/utils/modals";

import { useI18n } from "vue-i18n";
const { t } = useI18n();

const store = useStore();

const layoutType = computed(() => store.state.login.isType);

const iconNum = computed(() => store.getters['login/getLoginUser']?.sessionData?.account?.theme + 1);

const loginUser = computed(() => {
  return {
    uuid: store.getters["login/getLoginUser"]?.sessionData?.account?.uuid,
    userId: store.getters["login/getLoginUser"]?.sessionData?.account?.user_id,
    balance:
      store.getters["login/getLoginUser"]?.sessionData?.goods?.amount_retained,
    currency:
      store.getters["login/getLoginUser"]?.sessionData?.account?.currency_code,
    country:
      store.getters["login/getLoginUser"]?.sessionData?.account?.currency_code,
  };
});
const cols = [
  // {
  //   key: "userId",
  //   val: "userId",
  //   type: "string",
  // },
  {
    key: "balance",
    val: "balance",
    type: "number",
  },
  {
    key: "currency",
    val: "currency",
    type: "string",
  },
  {
    key: "country",
    val: "currency",
    type: "image",
  },
];
const getFlagImage = (curr) => {
  let img = undefined;
  try {
    img = require(`@/assets/flag/${curr}.png`);
  } catch (e) {
    img = require(`@/assets/flag/KRW.png`);
  }
  return img;
};

onMounted(async () => {
  await store.dispatch("request/call", {
    flag: "balance",
    params: { uuid: loginUser.value.uuid },
  });
});
</script>
<style lang="scss" scoped>
.myInfoWrap {
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px 20px;
}
.myInfoWrap .wrap_avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 6px;
  margin: 0px 0px 8px 0px;

  .avatar {
    width: 80px;
    position: relative;
    transition: all 0.5s ease-in-out;

    .action_choose_image {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 50%;
      background: rgb(0 0 0 / 75%);
      cursor: pointer;
      display: none;
      align-items: center;
      justify-content: center;
      border: 1px solid #e4d17b;
    }

    img {
      width: 100%;
      object-fit: contain;
    }

    &:hover {
      .action_choose_image {
        transition: all 0.5s ease-in-out;
        display: flex;

        svg path {
          stroke: #e4d17b;
        }
      }
    }
  }

  .valueInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #e4d17b;
    font-size: 18px;
  }
}
.layoutType2 .wrap_avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  max-width: 180px;
  padding: 4px 20px;
  margin: 12px 0 24px;
  border-radius: 100px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.28) 0%,
    rgba(153, 153, 153, 0.42) 100%
  );
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  .avatar {
    position: absolute;
    left: 0;
    width: 80px;
  }

  .valueInfo {
    justify-content: flex-end;
  }
}
.myInfoWrap .infoWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  align-items: center;
}

.myInfoWrap .infoWrap .label {
  color: #fff;
  white-space: nowrap;
  font-weight: bold;
}

.myInfoWrap .infoWrap .info {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
  text-align: left;
  color: #e4d17b;
}
</style>
