<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
  >
    <path
      opacity="0.4"
      d="M26.8865 6.39666C22.9399 2.46332 16.5399 2.46332 12.6199 6.39666C9.85987 9.12999 9.0332 13.0633 10.0999 16.53L3.8332 22.7967C3.3932 23.25 3.08654 24.1433 3.17987 24.7833L3.57987 27.69C3.72654 28.65 4.61987 29.5567 5.57987 29.69L8.48654 30.09C9.12654 30.1833 10.0199 29.89 10.4732 29.4233L11.5665 28.33C11.8332 28.0767 11.8332 27.65 11.5665 27.3833L8.97987 24.7967C8.5932 24.41 8.5932 23.77 8.97987 23.3833C9.36654 22.9967 10.0065 22.9967 10.3932 23.3833L12.9932 25.9833C13.2465 26.2367 13.6732 26.2367 13.9265 25.9833L16.7532 23.17C20.2065 24.25 24.1399 23.41 26.8865 20.6767C30.8199 16.7433 30.8199 10.33 26.8865 6.39666ZM19.8332 16.77C17.9932 16.77 16.4999 15.2767 16.4999 13.4367C16.4999 11.5967 17.9932 10.1033 19.8332 10.1033C21.6732 10.1033 23.1665 11.5967 23.1665 13.4367C23.1665 15.2767 21.6732 16.77 19.8332 16.77Z"
      fill="#054589"
    />
    <path
      d="M19.8333 16.77C21.6743 16.77 23.1667 15.2776 23.1667 13.4367C23.1667 11.5957 21.6743 10.1033 19.8333 10.1033C17.9924 10.1033 16.5 11.5957 16.5 13.4367C16.5 15.2776 17.9924 16.77 19.8333 16.77Z"
      fill="#054589"
    />
  </svg>
</template>
