<template>
  <div class="loginWrap">
    <div class="contentWrap" :class="{ mobile: utils.isMobile() }">
      <div class="loginInfoBox">
        <label class="title_input">ID</label>
        <div class="input-form">
          <component class="icon" :is="username" />
          <input
            placeholder="ID"
            v-model="dataModel.userId"
            @keyup.enter="login()"
          />
        </div>
      </div>
      <div class="loginInfoBox">
        <label class="title_input">password</label>
        <div class="input-form">
          <component class="icon" :is="password" />
          <input
            :type="`${isShowPass == true ? 'text' : 'password'}`"
            placeholder="Password"
            v-model="dataModel.pass"
            @keyup.enter="login()"
          />
          <component
            v-if="isShowPass"
            class="icon-eye"
            :is="uneye"
            @click.stop="isShowPass = !isShowPass"
          />
          <component
            v-else
            class="icon-eye"
            :is="eye"
            @click.stop="isShowPass = !isShowPass"
          />
        </div>
      </div>
      <div class="formWrap3">
        <button class="btn1" @click.stop="login()">
          {{ t("accountLogin") }}
        </button>
        <div class="errMsg" v-if="errMsg">
          <div>{{ errMsg }}</div>
        </div>
        <div class="wrap_action_login">
          <button
            class="btn2"
            @click.stop="toggleShow('signup'), (musicType = 'click')"
          >
            {{ t("createAccount") }}
          </button>
          <button
            class="changeSkin"
            @click="handleChangeSkin(1)"
          >
            {{ t("changeSkin") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <Music :musicType="musicType" />
</template>
<script setup>
import { ref, reactive, watch, onUnmounted } from "vue";
import { fn } from "@/views/login/login.js";

import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { toggleShow } from "@/utils/modals";
import Music from "@/components/music/Music";
import { utils } from "@/utils/common";
import { username, password, eye, uneye } from "@/assets/svg/login";
import { useStore } from "vuex";
const store = useStore();

const { t } = useI18n();
const dataModel = reactive({
  userId: "",
  pass: "",
});

const route = useRoute();
const router = useRouter();

const errMsg = ref(undefined);
const musicType = ref(false);
const isShowPass = ref(false);

const handleChangeSkin = (status) =>{
  localStorage.setItem("type", status);
  store.state.login.isType = status;
}

const login = async () => {
  musicType.value = "click";
  const res = await fn.login(dataModel);

  if (!res.data.is_success) {
    errMsg.value = t("invalidIdPass");

    setTimeout(() => {
      errMsg.value = undefined;
    }, 1000);

    return;
  } else {
    localStorage.setItem("type", 0);
    store.state.login.isType = 0;
  }
  toggleShow("login");
};

watch(
  () => musicType.value,
  (val) => {
    if (val) {
      setTimeout(() => {
        musicType.value = undefined;
      }, 100);
    }
  }
);
</script>
<style lang="scss" scoped>
/* 체크박스 off 상태 */
input[type="checkbox"] + label {
  display: block;
  width: 18px;
  height: 18px;
  background: url("../../assets/icon/img.png") no-repeat 0 0 / contain;
  cursor: pointer;
}

/* 체크박스 on 상태 */
input[type="checkbox"]:checked + label {
  background: url("../../assets/icon/icon_marker.png") no-repeat 0 0 / contain;
}

/* 기존 체크박스 사라지게 */
input[type="checkbox"] {
  display: none;
}
.logoWrap {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  font-size: 46px;
}
.loginWrap {
  width: 100%;
  height: 100%;
}
.loginWrap .contentWrap .formWrap {
  display: flex;
  justify-content: center;
  width: inherit;
  align-items: center;
  gap: 6px;
}
.loginWrap .contentWrap .formWrap .verticalLine {
  width: 5px;
  height: 22px;
  background-color: rgba(255, 238, 172, 1);
}
.loginWrap .contentWrap .formWrap {
  font-size: 26px;
  color: rgba(255, 238, 172, 1);
}

.loginWrap .contentWrap .formWrap2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  font-size: 12px;
  position: relative;
  bottom: 30px;
}
.loginWrap .contentWrap .formWrap3 {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  align-items: center;

  .wrap_action_login{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    font-size: 18px;
    .changeSkin{
      color: #fff;
      font-size: 18px;
      background: url("@/assets/image/change-new.png") no-repeat;
      width: 100%;
      padding: 0 12px;
      background-size: 100% 100%;
    }
  }
  

  @media (max-width: 768px) {
    gap: 12px;
  }
}

.tx {
  color: #b2b2b2;
  font-size: 16px;
}

.loginWrap .contentWrap .formWrap2 .leftWrap {
  display: flex;
  align-items: center;
  gap: 4px;
}
.loginWrap .contentWrap {
  max-width: 330px;
  height: max-content;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 12px;
  }
}

.loginWrap .contentWrap.mobile {
  width: 90%;
}

.loginInfoBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.loginInfoBox .title_input {
  color: #fff;
  font-size: 18px;
  text-transform: capitalize;
}

.loginInfoBox .input-form {
  height: 50px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0px 0px 0px 10px;
  border-radius: 12px;
  border: 0.304px solid #fff;
  background: #dedede;
  backdrop-filter: blur(6.0781121253967285px);

  @media (max-width: 768px) {
    gap: 12px;
    backdrop-filter: none;
  }

  .icon-eye {
    margin-right: 4px;
    width: 25px;
    cursor: pointer;
  }
}

.loginInfoBox .input-form input {
  width: 100%;
  height: 100%;
  color: #202020;
  font-size: 18px;
}

.loginInfoBox .input-form input::placeholder {
  color: #797979;
}

button {
  font-size: 24px;
  letter-spacing: 0.56px;
  color: #c0a260;
  width: 100%;
  height: 50px;
  border: 1px solid transparent;
  background-clip: content-box;
  background-size: 100% 100%;
  border-radius: 10px;
}

button.btn1 {
  background: transparent;
  border: 1px solid #dac16e;
  font-size: 20px;
  color: #fff;
  transition: all 0.3s ease-in-out;

  &:hover {
    transition: all 0.3s ease-in-out;
    background: linear-gradient(
      180deg,
      #dac16e 0%,
      #f2e79b 20%,
      #fffec0 40%,
      #f0e7a1 60%,
      #dcc670 73.5%,
      #e4cf7d 100%
    );
    color: #202020;
    opacity: 1;
  }
}

button.btn2 {
  background: linear-gradient(180deg, #bdbdbd -80.61%, #353535 119.39%);
  border: 1px solid #bdbdbd;
  font-size: 20px;
  color: #f4d220;
}

.errMsg {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 20px 0;
  transform: translate(-50%, -50%);
  font-size: 18px;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;

  div {
    padding: 30px 100px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      top: 0;
      left: 0;
      background: linear-gradient(
        90deg,
        rgba(227, 206, 124, 0) 0%,
        #e3ce7c 49%,
        rgba(227, 206, 124, 0) 100%
      );
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        90deg,
        rgba(227, 206, 124, 0) 0%,
        #e3ce7c 49%,
        rgba(227, 206, 124, 0) 100%
      );
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
</style>
