<template>
  <div class="modalWrap" :class="{ mobile: utils.isMobile() }">
    <div
      class="bgBox"
      @click.stop="(musicType = 'click'), hide()"
      :class="[{ loginBg: modals['login'] }, { bgType2: typeLayout == 1 }]"
    ></div>
    <div
      class="modalBox"
      :class="[
        { login: modals['login'] == true },
        { layoutType2: typeLayout == 1 },
        { mobile: utils.isMobile() }
      ]"
      :style="props.styles"
    >
      <div
        v-if="props.title"
        class="modalCloseBtn"
        @click.stop="(musicType = 'click'), hide()"
      >
        <img src="@/assets/btn/btn_close.png" />
      </div>
      <div v-if="props.title" class="modalHeader">
        {{ t(props.title) }}
      </div>
      <div
        v-if="!isLogin"
        class="modalContents"
        :class="[{ useScroll: props.title }, { loginBg: modals['login'] }]"
      >
        <div
          v-if="modals['login']"
          class="modalInner login_signUp"
          :class="[{ loginBg: modals['login'] }]"
        >
          <img
            v-if="typeLayout == 1"
            class="dealer-login"
            loading="lazy"
            src="../../assets/background/dealer-login.png"
            alt="image"
          />
          <div class="logo_holdem">
            <img
              v-if="typeLayout == 1"
              loading="lazy"
              src="@/assets/logo/logo_holdem_init.png"
              alt="image"
            />
            <img
              v-else
              loading="lazy"
              src="@/assets/logo/logo_holdem_init.png"
              alt="image"
              class="logoType1"
            />
          </div>
          <div
            class="content_modal"
            :class="[{ loginBgContent: modals['login'] }]"
          >
            <slot></slot>
          </div>
        </div>
        <div v-else class="modalInner">
          <slot></slot>
        </div>
      </div>
      <div v-else class="modalContents">
        <Loading :isLogin="true" />
      </div>
    </div>
  </div>
  <Music :musicType="musicType" />
</template>
<script setup>
import { modals } from "@/utils/modals";
import { useI18n } from "vue-i18n";
import { utils } from "@/utils/common";
import { ref, watch, computed } from "vue";
import Music from "@/components/music/Music";
import { useStore } from "vuex";
import Loading from "@/components/loading/Loading.vue";

const props = defineProps({
  styles: {
    type: Object,
    default: () => {
      return {};
    },
  },
  title: {
    type: String,
    default: undefined,
  },
});

const { t } = useI18n();
const store = useStore();
const typeLayout = computed(() => store.state.login.isType);
const isLogin = computed(() => store.state.login.isLogin);

const emits = defineEmits(["hide"]);
const musicType = ref(false);

const hide = () => {
  emits("hide");
};

watch(
  () => musicType.value,
  (val) => {
    if (val) {
      setTimeout(() => {
        musicType.value = undefined;
      }, 100);
    }
  }
);
</script>
<style lang="scss" scoped>
.modalWrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
}

.bgBox.loginBg {
  background-image: url("@/assets/background/login.png");

  @media (max-width: 1000px) {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.bgType2.bgBox.loginBg {
  background-image: url("@/assets/background/loginType2.png");
}

.bgBox {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.modalBox {
  position: relative;
  width: auto;
  height: auto;
  color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  background: url('@/assets/background/bg_modal_menu_normal.png') no-repeat;
  background-size: cover;
  overflow: hidden;
  z-index: 3;
  border-radius: 20px;
  border: 1px solid #e4d17b;
}

.modalBox.layoutType2 {
  background: linear-gradient(180deg, #454545 0.07%, #000 155.48%);
  background: url('@/assets/background/bg_modal_menu.png') no-repeat;
  background-size: cover;
  backdrop-filter: blur(15px);
}

.modalBox.mobile{
  border-radius: 0px;
}

.modalBox.login {
  background: none;
  border: none;
}

.modalBox.login .modalHeader {
  display: none;
}

.modalBox.login .modalCloseBtn {
  top: 30px;
  right: 30px;
}

.modalBox .modalHeader {
  height: 58px;
  width: 100%;
  font-size: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: -30px;
}

.modalBox.layoutType2 .modalHeader {
  color: #e4d17b;
}

.modalCloseBtn {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 12px;
  right: 12px;
}

.modalCloseBtn img {
  cursor: pointer;
}

.modalContents {
  width: 100%;
  margin: 0 auto;
}

.modalContents.useScroll {
  margin-top: 20px;
  height: calc(100% - 68px);
}

.modalContents.loginBg {
  height: 100%;
  width: 100%;
  margin-top: 0px;
  display: flex;
  justify-content: end;
  align-items: center;
  background: url("@/assets/background/bg_left_login.png") no-repeat;
  background-size: 50% 100%;

  @media (max-width: 1300px) {
    background-size: 60% 100%;
  }

  @media (max-width: 1000px) {
    background: none;
  }
}
.layoutType2 .modalContents.loginBg {
  background: none;
}

.modalInner {
  width: 100%;
  height: 100%;
}

.login_signUp {
  width: 50%;
  height: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .logoType1{
    width: 240px;
  }

  @media (max-width: 1300px) {
    width: 40%;
  }
  @media (max-width: 1000px) {
    width: 100%;
    padding: 12px;
  }

  @media (max-height: 420px) {
    transform: scale(0.7);
    gap: 20px;
    min-height: 330px;
  }
}

.login_signUp img {
  object-fit: contain;
}

.layoutType2 .login_signUp {
  width: 100%;
  height: auto;
  max-width: 926px;
  min-height: 400px;
  background: url("@/assets/background/bg_form_login.png") no-repeat;
  background-size: contain;
  background-position-x: 50%;
  margin: 0 auto;
  padding: 0;
  justify-content: flex-start;
  gap: 34px;
  overflow: initial;
  position: relative;

  @media (max-width: 1000px) {
    background-size: cover;
  }

  @media (max-height: 420px) {
    transform: scale(0.8);
  }
}
.layoutType2 .dealer-login {
  position: absolute;
  width: 30%;
  left: 0;
  bottom: 0;
  z-index: 3;

  @media (max-width: 700px) {
    width: 36%;
  }

  @media (max-width: 550px) {
    display: none;
  }
}

.layoutType2 .logo_holdem {
  width: 340px;
  height: 50px;
  background: url("@/assets/background/bg_logo_login.png") no-repeat;
  background-size: 100% 100%;
  position: relative;

  img {
    width: 100%;
    transform: scale(0.7);
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 200px;
  }

  @media (max-width: 350px) {
    width: 270px;
  }
}

.modalInner.mobile.loginBg {
  width: 100%;
}

.content_modal {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.layoutType2 .content_modal.loginBgContent {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.5) 0%,
    #14110a 52.61%,
    rgba(0, 0, 0, 0.5) 100%
  );
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0),
      #fae7a9 50%,
      hsla(0, 0%, 100%, 0) 96.4%
    );
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0),
      #fae7a9 50%,
      hsla(0, 0%, 100%, 0) 96.4%
    );
  }
}

@media (max-width: 1024px) {
  .modalBox {
    width: 100vw !important;
    height: 100vh !important;
  }
}
</style>
