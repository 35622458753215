import store from "@/store";
import { encryptDataV2 } from "@/utils/encrypt";

const fn = {
    // 코인 출금
    coinWithdrawal: async (payload) => {
        if(payload.value <= 0){
            return;
        }
        const res = await store.dispatch('request/call', {
            flag: 'coinWithdrawal',
            params: {
                "address" : store.getters['login/getLoginUser'].userId,
                "value" : payload.value,
                "symbol" : payload.symbol
            },
            headers: {}
        });
    },
    // 법정화폐 입금요청
    deposit: async (payload) => {
        if(payload.amount <= 0){
            return;
        }
        const res = await store.dispatch('request/call', {
            flag: 'deposit',
            params: {
                amount: encryptDataV2(String(payload.amount))
            },
            headers: {
                "publisher-private-key": "C105E791-F4F8-48C8-AC83-B550B3871C8A",
                "client-game-id": "C80A3C3E-99EF-4C37-819D-6E92A96AA1FA",
                "client-version-type": 1000000001,
                "Content-Type": "application/json, application/x-www-form-urlencoded",
            }
        });
        return res;
    },
    // 법정화폐 출금요청
    withdrawal: async (payload) => {
        if(payload.amount <= 0){
            return;
        }
        const res = await store.dispatch('request/call', {
            flag: 'withdrawal',
            params: {
                amount: encryptDataV2(payload.amount)
            },
            headers: {
                "publisher-private-key": "C105E791-F4F8-48C8-AC83-B550B3871C8A",
                "client-game-id": "C80A3C3E-99EF-4C37-819D-6E92A96AA1FA",
                "client-version-type": 1000000001,
                "Content-Type": "application/json, application/x-www-form-urlencoded",
            }
        });
        return res;
    },
    getTxRequestList: async (payload) => {
        const res = await store.dispatch('request/call', {
            flag: `${payload.type}History`,
            params: {
                start_date: encryptDataV2(payload.start_date),
                end_date: encryptDataV2(payload.end_date),
                search_limit: encryptDataV2("10")
            },
            headers: {
                "publisher-private-key": "C105E791-F4F8-48C8-AC83-B550B3871C8A",
                "client-game-id": "C80A3C3E-99EF-4C37-819D-6E92A96AA1FA",
                "client-version-type": 1000000001,
                "Content-Type": "application/json, application/x-www-form-urlencoded",
            }
        });
        return res;
    },
    getBetHistory: async () => {
        const res = await store.dispatch('request/call', { flag: 'betHistory', params: { page: encryptDataV2(String(1)), search_type: "", search_value: "", search_date: "" } });
        return res;
    },
    getCoinHistory: async (payload) => {
        const res = await store.dispatch('request/call', { flag: 'coinHistory', params: {
                "page" : payload.page,
                "size" : payload.size,
                "address" : store.getters['login/getLoginUser'].userId,
                "type" : payload.type,
                "symbol" : payload.symbol
            }, headers: { 'X-AUTH-TOKEN': store.getters['login/getLoginUser'].token } });
        return res;
    },
    selectDays: (e, searchDate) => {
        const targetValue = e.target.value;
        fn.setDays(searchDate, targetValue);
    },
    getToday: (start = null) => {
        let date = new Date(),
            year = date.getFullYear(),
            month = ("0" + (1 + date.getMonth())).slice(-2),
            day = ("0" + ((date.getDate() - start) > 1 ? (date.getDate() - start) : 1)).slice(-2);

        return year + "-" + month + "-" + day;
    },

    // 종료 날짜를 기준으로 단위 계산
    setStartDate: (number) => {
        const timeStamp = number * 24 * 60 * 60 * 1000;
        let _date = new Date().getTime() - timeStamp,
            date = new Date(_date),
            year = date.getFullYear(),
            month = ("0" + (1 + date.getMonth())).slice(-2),
            day = ("0" + date.getDate()).slice(-2);

        return year + "-" + month + "-" + day;
    },
    setDays: (searchDate, key) => {
        searchDate['endDate'] = fn.getToday();
        switch(key) {
            case 'today':
                searchDate['startDate'] = fn.setStartDate(0);
                break;
            case 'yesterday':
                searchDate['startDate'] = fn.setStartDate(1);
                break;
            case 'week':
                searchDate['startDate'] = fn.setStartDate(7);
                break;
            case 'month':
                searchDate['startDate'] = fn.setStartDate(30);
                break;
        }
    },
    blankHiperLink: (symbol, search ,hash) => {
        const _symbol = symbol === 'BNB' ? 'https://www.bscscan.com/' : 'https://etherscan.io/';
        const _search = search;
        const _hash = hash;
        return `${_symbol}${_search}/${_hash}`;
    }
}


export {
    fn
}