<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
  >
    <path
      opacity="0.4"
      d="M28.6056 12.2113V21.3312C28.6056 22.8246 27.8056 24.2113 26.5122 24.9713L18.5922 29.5446C17.2989 30.2913 15.6989 30.2913 14.3922 29.5446L6.47224 24.9713C5.17891 24.2246 4.37891 22.8379 4.37891 21.3312V12.2113C4.37891 10.7179 5.17891 9.33122 6.47224 8.57122L14.3922 3.9979C15.6856 3.25123 17.2856 3.25123 18.5922 3.9979L26.5122 8.57122C27.8056 9.33122 28.6056 10.7046 28.6056 12.2113Z"
      fill="#054589"
    />
    <path
      d="M16.509 16.7724C18.2248 16.7724 19.6157 15.3815 19.6157 13.6657C19.6157 11.9499 18.2248 10.5591 16.509 10.5591C14.7932 10.5591 13.4023 11.9499 13.4023 13.6657C13.4023 15.3815 14.7932 16.7724 16.509 16.7724Z"
      fill="#054589"
    />
    <path
      d="M20.0801 22.9853C21.1601 22.9853 21.7867 21.7854 21.1867 20.8921C20.2801 19.5454 18.5201 18.6387 16.5067 18.6387C14.4934 18.6387 12.7334 19.5454 11.8267 20.8921C11.2267 21.7854 11.8534 22.9853 12.9334 22.9853H20.0801Z"
      fill="#054589"
    />
  </svg>
</template>
