import store from '@/store';

const endPoints = {
    // 룸 정보 가져오기 ( 전체 ) * 레디스정보가져오기// key: room_info, dealer
    redisData: {
        url: '/maintenance/command/data',
        $setting: {
            pending: true,
            $useStore: (data) => {
                store.commit('cms/setRedisData', Object.assign(store.getters['cms/getRedisData'], data.const_data));
            },
            agentKeyPass: false
        }
    },
    chipData: {
        url: '/maintenance/command/data',
        $setting: {
            pending: true,
            $useStore: (data) => {
                const currencyCode = store.getters['login/getLoginUser']?.sessionData?.account?.currency_code;
                const chips = data.const_data.chip.find(v => v.code3 == currencyCode);
                if (chips) {
                    store.commit('cms/setUserChips', [chips.value_1, chips.value_2, chips.value_3, chips.value_4, chips.value_5, chips.value_6].map(v => {
                        return { value: v }
                    }));
                }
                store.commit('cms/setChipData', Object.assign(store.getters['cms/getChipData'], data.const_data));
            },
        }
    },
    cmsInfo: {
        url: '/api/cms/country/lists',
        $setting: {
            pending: true,
            $useStore: (data) => {
                store.commit('cms/setCmsInfo', data.data);
            },
            agentKeyPass: false
        }
    },
    cmsLogin: {
        url: '/api/certification/signin',
        $setting: {
            pending: true,
            $useStore: undefined,
            agentKeyPass: true
        }
    },
    reqAgentKey: {
        url: '/api/certification/verification/agent',
        $setting: {
            pending: true,
            $useStore: undefined,
            agentKeyPass: true
        }
    },
    logout: {
        url: '/api/auth/logout',
        $setting: {
            pending: true,
            $useStore: undefined,
            agentKeyPass: false
        }
    },
    balance: {
        url: '/api/auth/goods',
        $setting: {
            pending: true,
            $useStore: (data) => {
                store.commit('login/setUserBalance', data?.value?.amount_retained);
            },
            agentKeyPass: false
        }
    },
    get_ping: {
        url: '/maintenance/get/ping',
        $setting: {
            pending: true,
            $useStore: undefined,
            agentKeyPass: false
        }
    },
    notice: {
        url: '/api/cms/notice',
        $setting: {
            pending: true,
            $useStore: (data) => {
                store.commit('login/setNotice', data?.data.value);
            },
            agentKeyPass: false
        }
    },
    dealerImage: {
        url: '/api/profile/list',
        $setting: {
            pending: true,
            $useStore: undefined,
            agentKeyPass: false
        }
    },
    // 입금요청
    deposit: {
        url: '/transaction/set_deposit_request',
        $setting: {
            pending: true,
            $useStore: undefined,
            tokenPass: true
        },
    },
    // 출금요청
    withdrawal: {
        url: '/transaction/set_withdraw_request',
        $setting: {
            pending: true,
            $useStore: undefined,
            tokenPass: true
        },
    },
    // 최근 배팅히스토리 20건조회
    betHistory: {
        url: '/tool/history/member_teenpatti/detail',
        $setting: {
            pending: true,
            $useStore: (data) => {
                store.commit('history/setBetHistory', data.value);
            }
        }
    },
    sendTip: {
        url: '/api/pay/service_set',
        $setting: {
            pending: true,
            $useStore: undefined,
            tokenPass: true
        }
    },
    sendEnterState: {
        url: '/logic_api/set/enter/status',
        $setting: {
            pending: true,
            $useStore: undefined,
            tokenPass: true
        }
    },
    changeIcon: {
        url: '/api/auth/verificationIcon',
        $setting: {
            pending: true,
            $useStore: undefined,
            tokenPass: true
        }
    }
}

export {
    endPoints
}