<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      opacity="0.4"
      d="M21.7501 9.92C21.2601 9.14 20.7001 8.44 20.1201 7.81L16.3501 11.58C16.4701 11.95 16.5401 12.35 16.5401 12.77C16.5401 15.01 14.7301 16.81 12.5001 16.81C12.0801 16.81 11.6801 16.74 11.3101 16.62L7.8501 20.08C9.3101 20.9 10.8901 21.33 12.5001 21.33C14.2801 21.33 16.0101 20.81 17.5901 19.84C19.1701 18.86 20.5901 17.43 21.7501 15.61C22.7501 14.05 22.7501 11.49 21.7501 9.92Z"
      fill="#292D32"
    />
    <path
      d="M14.5201 10.75L10.4801 14.79C9.97014 14.27 9.64014 13.55 9.64014 12.77C9.64014 11.2 10.9201 9.91003 12.5001 9.91003C13.2801 9.91003 14.0001 10.24 14.5201 10.75Z"
      fill="#292D32"
    />
    <path
      opacity="0.4"
      d="M18.75 6.52001L15.36 9.91001C14.63 9.17001 13.62 8.73001 12.5 8.73001C10.26 8.73001 8.46 10.54 8.46 12.77C8.46 13.89 8.91 14.9 9.64 15.63L6.26 19.02H6.25C5.14 18.12 4.12 16.97 3.25 15.61C2.25 14.04 2.25 11.49 3.25 9.92001C4.41 8.10001 5.83 6.67001 7.41 5.69001C8.99 4.73001 10.72 4.20001 12.5 4.20001C14.73 4.20001 16.89 5.02001 18.75 6.52001Z"
      fill="#292D32"
    />
    <path
      d="M15.3601 12.77C15.3601 14.34 14.0801 15.63 12.5001 15.63C12.4401 15.63 12.3901 15.63 12.3301 15.61L15.3401 12.6C15.3601 12.66 15.3601 12.71 15.3601 12.77Z"
      fill="#292D32"
    />
    <path
      d="M22.2699 3.00002C21.9699 2.70002 21.4799 2.70002 21.1799 3.00002L2.72988 21.46C2.42988 21.76 2.42988 22.25 2.72988 22.55C2.87988 22.69 3.06988 22.77 3.26988 22.77C3.46988 22.77 3.65988 22.69 3.80988 22.54L22.2699 4.08002C22.5799 3.78002 22.5799 3.30002 22.2699 3.00002Z"
      fill="#292D32"
    />
  </svg>
</template>
