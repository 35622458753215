<template>
  <ul class="topBox" :class="{ mobile: utils.isMobile() }">
    <Tab :tabs="[{ tx: 'rule' }, { tx: 'rank' }]" @clickTab="clickTab" />
  </ul>
  <div class="listWrap">
    <div class="infoWrap">
      <div style="white-space: pre-line" v-if="type == 'rule'">
        {{ t("holdemRule") }}
      </div>
      <div style="white-space: pre-line" v-if="type == 'rank'">
        {{ t("holdemRank") }}
      </div>
    </div>
  </div>
</template>

<script setup>
import Tab from "@/components/tab/Tab";
import { utils } from "@/utils/common";
import { useI18n } from "vue-i18n";
import { computed, ref } from "vue";
const { t } = useI18n();
const type = ref("rule");
const clickTab = (tab) => {
  type.value = tab.tx;
};
</script>

<style scoped>
ul {
  padding: 0;
}
button {
  width: 120px;
  height: 24px;
  background-color: white;
  color: black;
  border: 1px solid #fff;
  font-size: 14px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
button {
  color: rgba(255, 238, 172, 1);
  border: 1px solid transparent;
  background-image: linear-gradient(to bottom, #141516, #1f2224),
    linear-gradient(
      to top,
      rgba(182, 156, 74, 1),
      rgba(212, 190, 117, 1),
      rgba(255, 238, 172, 1),
      rgba(212, 190, 117, 1),
      #b69c4a
    ),
    linear-gradient(to bottom, #867544, #867544);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
ul.topBox {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-size: 16pt;
}
ul.topBox.mobile {
  margin-top: 20px;
  padding-left: 0px;
}
.listWrap {
  width: 100%;
  height: calc(100% - 100px);
  margin: auto;
  padding: 20px 0;
}

.listWrap .infoWrap {
  display: flex;
  width: 98%;
  height: 100%;
  margin: 0 auto;
  color: #fff;
  gap: 14px;
  font-size: 14px;
  overflow-y: auto;
}
</style>
