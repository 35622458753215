import store from "@/store";
import { modals, toggleShow } from "@/utils/modals";
import { getCookie } from "@/utils/cookie";
import { fn } from "@/views/login/login.js";
import { encryptDataV2 } from "@/utils/encrypt";

const loginFilter = async (to, from, next) => {
  const link = document.querySelector("[rel='icon']");
  link.setAttribute("href", to.meta.icon);
  document.title = to.meta.title;
  if (!store.getters["login/getLoginUser"]?.sessionData) {
    let loginInfo = sessionStorage.getItem("CID");
    if (loginInfo) {
      try {
        await fn.login(JSON.parse(loginInfo).payload, 1);
      } catch (e) {
        console.error(e);
      }
    } else {
      toggleShow("login");
    }
  }
  if (to.meta.checkUserId && !store.getters["login/getLoginUser"]?.sessionData)
    next("/");
  else next();
};

export { loginFilter };
