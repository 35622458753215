<template>
  <div class="listWrap" :class="{layoutType2 : layoutType == 1}">
    <div v-if="!utils.isMobile()" class="bottomWrap">
      <div class="cols">
        <div v-for="(col, idx) in cols" :key="idx">
          {{ t(col.tx) }}
        </div>
      </div>
      <div class="datas" v-if="betHistory?.length == 0">
        {{ t('noData') }}
      </div>
      <div class="datas" v-else>
        <div class="data" v-for="(history, idx) in betHistory" :key="idx">
          <div>
            {{ idx + 1}}
          </div>
          <div>
            {{ history['table'] }}
          </div>
          <div>
            {{ history['round'] }}
          </div>
          <div>
            {{ history['betAction'] }}
          </div>
          <div v-numberFormat="history['betAmount']">
          </div>
          <div v-numberFormat="history['winAmount']">
          </div>
          <div>
            {{ history['slot'] }}
          </div>
          <div>
            {{ history['playtime'] }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="bottomWrap mobile">
      <div class="datas" v-if="betHistory?.length == 0">
        {{ t('noData') }}
      </div>
      <div class="datas" v-else>
        <div class="data mobile" v-for="(history, idx) in betHistory" :key="idx">
          <div>
            <span class="value">{{ idx + 1}}</span>
          </div>
          <div>
            <span class="value">{{ history['table'] }}</span>
          </div>
          <div>
            <span class="title">{{t('BET')}}</span> : 
            <span class="value">{{ history['round'] }}</span>
          </div>
          <div>
            <span class="title">{{t('BET')}}</span> : 
            <span class="value">{{ history['betAction'] }}</span>
          </div>
          <div>
            <span class="title">{{t('AMOUNT')}}</span> : 
            <span class="value">{{ utils.setNumberFormat(history['winAmount']) }}</span>
          </div>
          <div>
            <span class="title">{{t('WIN_AMOUNT')}}</span> : 
            <span class="value">{{ history['round'] }}</span>
          </div>
          <div>
            <span class="title">{{t('SEAT')}}</span> : 
            <span class="value">{{ history['slot'] }}</span>
          </div>
          <div>
            <span class="title">{{t('TIME')}}</span> : 
            <span class="value">{{ history['playtime'] }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { fn } from "@/views/transaction/transaction.js";
import { onBeforeMount, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { utils } from '@/utils/common';

const { t } = useI18n();
import { useStore } from "vuex";

const store = useStore();
const layoutType = computed(()=>store.state.login.isType);
const cols = [ { tx: 'NO' }, { tx: 'ROOM' },{ tx: 'ROUND' }, { tx: 'BET' }, { tx: 'AMOUNT' }, {tx: 'WIN_AMOUNT'}, { tx: 'SEAT' }, { tx: 'TIME' }]

const betHistory = computed(() => {
  return store.getters['history/getBetHistory'];
});

onBeforeMount(async () => {
  const res = await fn.getBetHistory();
});
</script>
<style lang="scss" scoped>
input:read-only {
  cursor: not-allowed;
}
input, button {
  width: 120px;
  height: 24px;
  background-color: white;
  color: black;
  border-radius: 6px;
  border: 1px solid #fff;
  font-size: 14px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
button {
  background-color: #1f1c27;
  color: white;
}
ul.topBox{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.listWrap {
  width: 98%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 36px;
  color: #fff;
  border-radius: 12px;
  padding: 10px;
}

.listWrap.layoutType2{
  gap: 15px;
}

.listWrap .leftWrap {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
}

.listWrap .searchDate input {
  display: flex;
  gap: 10px;
  border: 1px solid #ffffff;
  padding: 10px;
}

select, select option {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  border: 1px solid #ffffff;
  width: 85px;
  height: 24px;
}

.listWrap .bottomWrap {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.listWrap .bottomWrap .cols {
  display: flex;
  width: 100%;
  height: 53px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  border-radius: 10px;
  background: linear-gradient(171deg, #F6FFEE 6.98%, #EFF4E8 20.87%, #DBD6D9 45.34%, #DAD4D8 46.93%);
  line-height: 20px;
  padding-bottom: 0;
  border-bottom: none;

  @media (max-width: 1200px) {
    font-size: 16px;
  }
}

.listWrap.layoutType2 .bottomWrap .cols{
  background: linear-gradient(180deg, #D0931C 0%, #2F2D22 100%);
}

.listWrap .bottomWrap .datas .data {
  display: flex;
  width: 100%;
  height: 40px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  margin: 0;

  &:nth-child(odd){
    background-color: #8f8f8f52;
  }

  @media (max-width: 1200px) {
    font-size: 16px;
  }
}


.listWrap.layoutType2 .bottomWrap .datas .data{
  &:nth-child(odd){
    background-color: #242424;
  }
}

.listWrap .bottomWrap.mobile .datas{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.listWrap .bottomWrap .datas .data.mobile {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  grid-gap: 5px 10px;
  width: 100%;
  min-height: 100px;
  height: 100%;
  font-size: 20px;
  padding: 5px 10px;

  @media (max-width: 375px) {
    grid-template-columns: repeat(1, 1fr);
  }

}

.cols div {
  flex: 1;
  white-space: nowrap;
  max-width: 150px;
  text-align: center;
  color: #1A1A1A;
  padding: 0 6px;
  margin-top: 0;
  display: flex;
  align-items: center;
}

.layoutType2 .cols div{
  color: #fff;
}

.datas {
  margin-top: 10px;
  height: calc(100% - 63px);
  overflow-y: auto;
}

.datas .data div {
  flex: 1;
  text-align: left;
  max-width: 150px;
  overflow: hidden;
  color: #fafafa;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  padding: 0 6px;
}


.datas .data.mobile div {
  max-width: 100%;

  .title{
    color: #757575;
    text-transform: lowercase;
  }
  .value{
    color: #fff;
  }
}

/* userID 클릭 시 bscscan.com으로이동 */
a{
  text-decoration: none !important;
  color: #fff;
  transition: all .5s;
}
a.BNB:hover{
  color: #FBDA3C;
}
a.ETH:hover{
  color: #21325b;
}
</style>