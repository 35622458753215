import { createRouter, createWebHistory } from "vue-router";
import { loginFilter } from "@/router/filter/login";
import { launchFilter } from "@/router/filter/launch";
import { utils } from "@/utils/common";

const routes = [
  {
    path: "/",
    redirect: "/out-game",
  },
  {
    path: "/out-game",
    name: "outgame",
    component: () => import("@/layout/navLayout"),
    children: [
      {
        path: "",
        name: "outgame",
        component: () => import("@/views/lobby/Index.vue"),
      },
    ],
    meta: {
      /*            isMobile: utils?.isMobile(),*/
      showNav: true,
      title: "Holdem",
      icon: "holdem.ico",
    },
  },
  {
    path: "/room/:tableIdx",
    component: () => import("@/layout/navLayout"),
    children: [
      {
        path: "",
        name: "inGame",
        component: () => import("@/views/inGame/Index.vue"),
      },
    ],
    meta: {
      checkUserId: true,
      /*            isMobile: utils?.isMobile(),*/
      showNav: false,
      title: "Holdem",
      icon: "holdem.ico",
    },
  },
  /*  {
        path: '/mobile/inGame',
        name: 'inGameM',
        component: () => import('@/layout/mobileLayout'),
        children: [
            {
                path: '',
                name: 'inGameM',
                component: () => import('@/views/inGame/Index.vue')
            },
        ],
        meta: {
            checkUserId: true,
            isMobile: utils.isMobile()
        },
    },*/
  {
    path: "/404",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "@/views/NotFound.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(launchFilter);
router.beforeEach(loginFilter);

export default router;
