import store from "@/store";
import {setCookie, deleteCookie} from '@/utils/cookie';
import {encryptDataV1, encryptDataV2, encryptLoginData} from '@/utils/encrypt';
import {fn as inGameFn} from "@/views/inGame/inGame";
import { ref } from "vue";

const moneyInterval = ref(undefined);

const fn = {
    login: async (payload, isReload = 0) => {
        if (!payload['userId'] || !payload['pass']) {
            return;
        }
        let agentKey = undefined;

        if(!payload['agent'])
            agentKey = "Pt3iqc2m6BcjseqZO63m11pOajATZqbs";
        else
            agentKey = payload.agent;

        const res = await store.dispatch('request/call', {
            flag: 'cmsLogin', params: {
                user_id: encryptLoginData(payload['userId']),
                password: encryptLoginData(payload['pass']),
                grade: encryptLoginData(String(1)),
                agent_key : encryptLoginData(String(agentKey)),
                token : payload.token,
                isReload: isReload
            },
            headers: {}
        });

        if(res?.data.msg){
            alert('중복 로그인 계정입니다.');
            return;
        }

        if(res?.data.is_success)
        {
            store.commit('login/setLoginUser', res.data.value);
            store.commit('login/setIsLoading', true);
            await store.dispatch('request/call', { flag: 'redisData', params: {key: encryptDataV2("TABLE_GAME_LIMITS") } });
            await store.dispatch('request/call', { flag: 'chipData', params: {key: encryptDataV2("chip") } });
            await store.dispatch('request/call', { flag: 'balance', params: { uuid: res.data.value.uuid } });
            sessionStorage.setItem('CID', JSON.stringify({
                data: res.data.value,
                payload: {
                    userId: payload.userId,
                    pass: payload.pass
                }
            }));
        }
        store.commit('login/setIsLoading', false);

        return res;
    },
    logout: async () => {
        deleteCookie('CID');
        const res = await store.dispatch('request/call', {
            flag: 'logout', params: {},
            headers: {}
        });
    }
}

export {
    fn
}
