<template>
  <Modal
    v-if="modals['login']"
    @hide="toggleShow('login')"
    :styles="{ width: '100vw', height: '100vh' }"
  >
    <Login v-if="typeLayout == 0" />
    <LoginType v-else />
  </Modal>
  <Modal
    v-if="modals['signup']"
    @hide="toggleShow('signup')"
    :styles="{ width: '100vw', height: '100vh' }"
    title="signup"
  >
    <SignUp v-if="typeLayout == 0" />
    <SignUpType v-else />
  </Modal>
  <Modal
    v-if="modals['history']"
    @hide="toggleShow('history')"
    :styles="{ width: '1408px', height: '838px' }"
    title="transactionHistory"
  >
    <TransactionHistory />
  </Modal>
  <Modal
    v-if="modals['depositWithdraw']"
    @hide="toggleShow('depositWithdraw')"
    :styles="{ width: '570px', height: '480px' }"
    title="depositWithdraw"
  >
    <Transaction />
  </Modal>
  <Modal
    v-if="modals['myInfo']"
    @hide="toggleShow('myInfo')"
    :styles="{ width: '300px', height: '300px' }"
    title="myInfo"
  >
    <MyInfo />
  </Modal>
  <Modal
    v-if="modals['tutorial']"
    @hide="toggleShow('tutorial')"
    :styles="{ width: '1000px', height: '750px' }"
    title="tutorial"
  >
    <Tutorial />
  </Modal>
  <Modal
    v-if="modals['dealerTip']"
    @hide="toggleShow('dealerTip')"
    :styles="{ width: '800px', height: '444px' }"
    title="dealerTip"
  >
    <DealerTip />
  </Modal>
  <Modal
    v-if="modals['privateRoom']"
    @hide="toggleShow('privateRoom')"
    :styles="{ width: '570px', height: '280px' }"
    title="privateRoom"
  >
    <PrivateRoom />
  </Modal>

  <Modal
      v-if="modals['selectCard']"
      @hide="toggleShow('selectCard')"
      :styles="{ width: '1500px', height: '750px' }"
      title="selectCard"
  >
    <SelectCard />
  </Modal>

  <Modal
    v-if="modals['avatar']"
    @hide="toggleShow('avatar')"
    :styles="{ width: '700px', height: '650px' }"
    title="profilePhoto"
  >
    <Avatar />
  </Modal>
</template>
<script setup>
import Modal from "@/components/modal/Modal.vue";
import Login from "@/views/login/Login.vue";
import SignUp from "@/views/signup/SignUp.vue";
import Avatar from "@/views/avatar";
import LoginType from "@/views/login/LoginType.vue";
import SignUpType from "@/views/signup/SignUpType.vue";
import TransactionHistory from "@/views/transaction/TransactionHistory.vue";
import Transaction from "@/views/transaction/Transaction.vue";
import MyInfo from "@/views/myInfo/MyInfo.vue";
import DealerTip from "@/views/dealerTip/DealerTip.vue";
import PrivateRoom from "@/views/privateRoom/PrivateRoom.vue";
import SelectCard from "@/views/selectCard/SelectCard.vue";

import { modals, toggleShow } from "@/utils/modals";
import Tutorial from "@/views/Tutorial/Tutorial";
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();
const typeLayout = computed(() => store.state.login.isType);
</script>
