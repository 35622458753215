<template>
  <div class="privateRoomWrap">
    <div class="privateRoomConfirm" :class="{ 'mobile': utils.isMobile() }">
      <div class="userMoney dealerTip" :class="{ 'mobile': utils.isMobile() }">
        <input class="userMoneyText" style="text-align:right;" :class="{ 'mobile': utils.isMobile() }" v-model="roomPassword"/>
      </div>
      <button class="confirmButton" :class="[{'disable' : !isAble}, { 'mobile': utils.isMobile() }]" @click.stop="onClickEnterRoom()">
        {{ t('EnterRoom') }}
      </button>
    </div>
    <div v-if="modalMessage != undefined" class="errMsg err" :class="[{ 'mobile': utils.isMobile() }]">
      {{ t(modalMessage) }}
    </div>
  </div>
</template>

<script setup>
import Tab from "@/components/tab/Tab";
import { utils } from "@/utils/common";
import { useI18n } from "vue-i18n";
import {computed, ref, onMounted, watch} from "vue";
import {useStore} from "vuex";
import {toggleShow} from "@/utils/modals";
import {encryptLoginData, encryptDataV2, encryptDataV1} from "@/utils/encrypt";
import {useRoute, useRouter} from "vue-router";
import {topics, sendTopicMessage} from "@/plugins/mqtt/mqtt";
import {GAME_STATUS} from "@/const/const";
const { t } = useI18n();
const type = ref('rule');
const route = useRoute();
const router = useRouter();

const modalMessage = ref(undefined);

const tipMoney = ref(0);

const errMsg = ref(undefined);

const isAble = computed(() => {

  let status = GAME_STATUS[topics?.[`${route.params.tableIdx}/live_bacc/RoomInfo`]?.status] || GAME_STATUS[0];

  if(status == GAME_STATUS[0] || status == GAME_STATUS[9] || status == GAME_STATUS[10])
    return true;
  else
    return false;
});

const store = useStore();
const roomPassword = ref(undefined);

const loginUser = computed(() => {
  return {
    userId: store.getters["login/getLoginUser"]?.sessionData?.account?.user_id,
    balance: store.getters["login/getLoginUser"]?.sessionData?.goods?.amount_retained,
    currency: store.getters["login/getLoginUser"]?.sessionData?.currency_code,
    type: store.getters["login/getLoginUser"]?.sessionData?.account?.player_type
  }
});

const onClickEnterRoom = async () => {
  let idx = store.getters.getSelectRoomNum;

  if(store.getters['cms/getRedisData']?.table_game_limits[idx - 51].private_number === roomPassword.value)
  {
    router.push({ name: `inGame`, params: {tableIdx: idx} });
    toggleShow('privateRoom');
  }
  else
  {
    modalMessage.value = t('wrong_password');
    setTimeout(()=>{
      modalMessage.value = undefined;
    },3000);
    return;
  }
};



</script>

<style scoped>
.dealerImgBox {
  position: absolute;
  top: 10px;
  left: 50px;
  background-image: url('../../assets/box/dealer_box.png');
  background-size: 100% 100%;
  width: 138px;
  height: 143px;
  display: flex;
  justify-content: center;
  align-content: center;
  z-index: 10;
}
.dealerImgBox.mobile{
  top: 15px;
  left: 0px;
  transform: scale(0.8);
}
.dealerImgBox img {
  border-radius: 50%;
  width: 100px;
  object-fit: contain;
  position: absolute;
  top: 9px;
  left: 22px;
}
.privateRoomWrap {
  width: 100%;
  margin: auto;
  padding-top: 35px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 25px;
  position: relative;
}
.privateRoomWrap .myMoney{
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.privateRoomWrap .myMoney.mobile{
  width: 60%;
  margin-left: 120px;
}
.privateRoomWrap .tipMoney{
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 50px;
}
.privateRoomWrap .myMonetTitle{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
}
.privateRoomWrap .userMoney{
  width: 284px;
  height: 53px;
  background-image: url("../../assets/box/lobbyinfobox.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.privateRoomWrap .userMoney.mobile{
  width: 100%;
}
.privateRoomWrap .userMoney.dealerTip{
  width: 260px;
  height: 53px;
  background-image: url("../../assets/box/lobbyinfobox.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}
.privateRoomWrap .userMoney.dealerTip.mobile{
  width: 90%;
}
.privateRoomWrap .userMoneyIcon{
  position: relative;
  width: 36px;
  height: 32px;
  background-image: url("../../assets/holdem/icon/won.png");
  background-size: 100% 100%;
  left: 15px;
}
.privateRoomWrap .userMoneyIcon.mobile{
  width: 26px;
  height: 22px;
}
.privateRoomWrap .userMoneyText{
  position: relative;
  font-size: 24px;
  color: #E2EF3D;
  right: 15px;
}
.tipButtonGroup{
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  column-gap: 30px;
}
.privateRoomWrap button {
  width: 200px;
  height: 50px;
  color: #fafafa;
  font-size: 24px;
  object-fit: contain;
  border: solid 1px #33758a;
  background-image: linear-gradient(to top, #0e1a1e, #1d2a2e);
}
.privateRoomWrap .tipButtonGroup.mobile button{
  width: 43%;
}
.privateRoomWrap .confirmButton{
  width: 160px;
  height: 50px;
  color: #fafafa;
  font-size: 24px;
  object-fit: contain;
  box-shadow: 0px 1px 0.4px 0.6px rgba(0, 0, 0, 0.85);
  border: solid 2px #398df6;
  background-image: linear-gradient(to bottom, #2269c3, #0e51a4);
}
.privateRoomWrap .confirmButton.mobile{
  width: 90%;
}
.privateRoomWrap .confirmButton.disable{
  opacity: 0.5;
  cursor: not-allowed;
}
.privateRoomConfirm{
  width: 75%;
  height: 160px;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.privateRoomConfirm.mobile{
  flex-direction: column;
  width: 100%;
}

.privateRoomWrap .errMsg.mobile{
  width: 90%;
}

.privateRoomWrap .errMsg{
  font-size: 20px;
  color: yellowgreen;
}
.privateRoomWrap .errMsg.err{
  color: red;
  position: absolute;
  bottom: 2px;
}
</style>