import router from "@/router/index";
import store from "@/store";
import { utils } from "@/utils/common";
import { deleteCookie } from "@/utils/cookie";

const state = () => ({
  loginUser: {},
  isLogin: false,
  isType: localStorage.getItem("type") ? localStorage.getItem("type") : 0,
  ping: 0,
});

const mutations = {
  setLoginUser: (state, payload) => {
    state.loginUser = JSON.parse(JSON.stringify(payload));
  },
  initLoginUser: (state) => {
    state.loginUser = {};
  },
  setUserBalance: (state, payload) => {
    if (state.loginUser.sessionData && state.loginUser.sessionData.goods) {
      state.loginUser.sessionData.goods.amount_retained = payload;
    }
  },
  setAgentKey: (state, payload) => {
    state.agentKey = payload;
  },
  setNotice: (state, payload) => {
    state.notice = payload;
  },
  setPing: (state, payload) => {
    state.ping = payload;
  },
  setIsLoading: (state, payload) => {
    state.isLogin = payload;
  },
  setProfile: (state, payload) => {
    state.loginUser.sessionData.account.theme = payload;
  }
};

const getters = {
  getLoginUser: (state) => {
    return state.loginUser;
  },
  getAgentKey: (state) => {
    return state?.loginUser?.agent_private_key;
  },
  getUserBalance: (state) => {
    return state.loginUser.sessionData.goods.amount_retained;
  },
  getNotice: (state) => {
    return state.notice;
  },
  getPing: (state) => {
    return state.ping;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
