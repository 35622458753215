<template>
  <div class="loginWrap">
    <div class="wrap_content_login">
      <div class="content">Welcome to HOLDEM</div>
    </div>
    <div class="contentWrap">
      <div class="longWrap">
        <div class="infoBoxLong">
          <div class="input-form">
            <input placeholder="ID" v-model="dataModel.userId" />
            <component class="icon" :is="user2" />
          </div>
        </div>
        <div class="infoBoxLong">
          <div class="input-form">
            <input type="email" placeholder="Email" />
            <component class="icon" :is="email2" />
          </div>
        </div>
        <div class="infoBoxLong">
          <div class="input-form">
            <input
              type="password"
              placeholder="Password"
              v-model="dataModel.pass"
            />
            <component class="icon" :is="password2" />
          </div>
        </div>
      </div>
      <div class="formWrap3">
        <button class="btn1" @click.stop="login(), (musicType = 'click')">
          {{ t("createAccount") }}
        </button>
        <div>
          <span>{{ t("alreadyMember") }}</span
          ><span
            class="yellow"
            style="color: #dac16e"
            @click.stop="toggleShow('signup'), (musicType = 'click')"
            >{{ t("login") }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <Music :musicType="musicType" />
</template>
<script setup>
import { ref, reactive, watch } from "vue";
import { fn } from "@/views/login/login.js";
import { useI18n } from "vue-i18n";
import { toggleShow } from "@/utils/modals";
import { useStore } from "vuex";
import Music from "@/components/music/Music";
import { user2, password2, email2 } from "@/assets/svg/login";

const { t } = useI18n();
const store = useStore();

const dataModel = reactive({
  userId: "",
  pass: "",
});

const musicType = ref(false);

const login = async () => {
  const res = await fn.login(dataModel);

  if (res.data.is_success) {
    toggleShow("signup");
    toggleShow("login");
  }
  if (res.data.Message.indexOf("비밀번호") > -1) {
    store.commit("setErr", "이미 존재하는 계정입니다.");
  }
};

watch(
  () => musicType.value,
  (val) => {
    if (val) {
      setTimeout(() => {
        musicType.value = undefined;
      }, 100);
    }
  }
);
</script>
<style scoped>
.loginWrap {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: calc(100% - 400px) auto;
  align-items: center;

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
}

.wrap_content_login {
  display: flex;
  align-items: center;
  justify-content: end;

  .content {
    color: #f4f7ee;
    text-align: center;
    text-shadow: 0px 2.5px 0px #14110c;
    -webkit-text-stroke-width: 0.7890625px;
    -webkit-text-stroke-color: #a36909;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 230px;
  }

  @media (max-width: 850px) {
    .content {
      font-size: 30px;
      width: 170px;
    }
  }

  @media (max-width: 768px) {
    .content {
      display: none;
    }
  }
}

.loginWrap .contentWrap .formWrap3 {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  align-items: center;
  margin-top: 12px;
}

.loginWrap .contentWrap {
  max-width: 330px;
  height: max-content;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 12px;
  padding: 20px 12px;
}
.longWrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.infoBoxLong {
  width: 100%;
}

.infoBoxLong .input-form {
  height: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px 10px 0px 15px;
  border-radius: 20px;
  border: 0.304px solid #fff;
  background: #fff;
  backdrop-filter: blur(6.0781121253967285px);

  @media (max-width: 768px) {
    gap: 12px;
    backdrop-filter: none;
  }

  .icon-eye {
    margin-right: 4px;
    width: 25px;
    cursor: pointer;
  }
}

.infoBoxLong .input-form input {
  width: 100%;
  height: 100%;
  color: #202020;
  font-size: 18px;
}

.infoBoxLong .input-form input::placeholder {
  color: #242424;
  font-size: 16px;
}

button {
  font-size: 24px;
  letter-spacing: 0.56px;
  color: #c0a260;
  width: 100%;
  height: 40px;
  border: 1px solid transparent;
  background-clip: content-box;
  background-size: 100% 100%;
  border-radius: 20px;
}

button.btn1 {
  background: linear-gradient(180deg, #ded260 0.01%, #c1a962 100%);
  border: 1px solid #fcf6bb;
  font-size: 20px;
  color: #202020;
  transition: all 0.3s ease-in-out;

  &:hover {
    transition: all 0.3s ease-in-out;
    background: linear-gradient(
      180deg,
      #dac16e 0%,
      #f2e79b 20%,
      #fffec0 40%,
      #f0e7a1 60%,
      #dcc670 73.5%,
      #e4cf7d 100%
    );
    opacity: 1;
  }
}

.formWrap3 div {
  display: flex;
  gap: 10px;
}

.formWrap3 div span:last-child {
  cursor: pointer;
}
</style>
