import store from '@/store';

const state = () => ({
    cmsInfo: [],
    redisData: {},
    chipData: {},
    chips: [
        { value: 1000 },
        { value: 2000 },
        { value: 5000 },
        { value: 25000 },
        { value: 100000 },
        { value: 500000 }
    ],
});

const mutations = {
    setCmsInfo: (state, data) => {
        state.activeRound = data;
    },
    setRedisData: (state, data) => {
        state.redisData = data;
    },
    setChipData: (state, data) => {
        state.chipData = data;
    },
    setUserChips: (state, data) => {
        state.chips = data;
    },
}

const getters = {
    getRedisData: (state) => {
        return state.redisData;
    },
    getChipData: (state) => {
        return state.redisData;
    },
    getChips: (state) => {
        return state.chips.map((v, idx) => {
            if (store.getters['login/getUserBalance'] - store.getters['login/getUserTotalBet'] < v.value) {
                v.disabled = true;
            }
            return v;
        });
    },
}

const actions = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}