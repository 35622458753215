<template>
  <div class="tabWrap" :class="{layoutType2: layoutType == 1}">
    <div
      class="tab"
      :class="{ selected: tabIdx == idx }"
      v-for="(tab, idx) in props.tabs"
      :key="idx"
      @click.stop="clickTab(tab, idx)"
    >
      {{ t(tab.tx) }}
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

const store = useStore();
const { t } = useI18n();
const props = defineProps({
  tabs: {
    type: Array,
    default: () => {
      return [];
    },
  },
});
const emits = defineEmits(["clickTab"]);
const layoutType = computed(()=>store.state.login.isType);
const tabIdx = ref(0);
const clickTab = (tab, idx) => {
  tabIdx.value = idx;
  emits("clickTab", tab);
};
</script>
<style lang="scss" scoped>
.tabWrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 20px;
}
.tabWrap div {
  color: #1A1A1A;
  font-size: 18px;
  font-weight: 600;
}
.tabWrap .tab {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  height: 42px;
  padding: 10px;
  border-radius: 10px;
  background: linear-gradient(180deg, #FBFBFB 0%, #8C8C8C 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.layoutType2 .tab{
	border-radius: 100px;
	color: #fff;
	background: linear-gradient(180deg, #454545 0.07%, #DDB01B 0.08%, #1F1F1F 155.48%);
}
.tab.selected {
	color: #fff;
	background: linear-gradient(180deg, #BDBDBD 0.07%, #666 0.08%, #282828 155.48%)
}
.tabWrap .tab:hover:not(.selected){
	background: linear-gradient(180deg, #BDBDBD 0.07%, #666 0.08%, #282828 155.48%)
}

.layoutType2 .tab.selected{
	background: linear-gradient(180deg, #BDBDBD 0.07%, #666 0.08%, #282828 155.48%);
}

</style>
