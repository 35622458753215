<template>
  <div class="tipWrap" :class="{ layoutType2: typeLayout == 1 }">
    <div class="myMoney" :class="{ mobile: utils.isMobile() }">
      <div class="userMoney" :class="{ mobile: utils.isMobile() }">
        <div class="myMonetTitle">{{ t("holdingAmount2") }}</div>
        <div class="userMoneyText" :class="{ mobile: utils.isMobile() }">
          {{ utils.setNumberFormat(loginUser.balance) }}
        </div>
      </div>
    </div>
    <div class="tipMoney">
      <div class="tipButtonGroup" :class="{ mobile: utils.isMobile() }">
        <button class="tipButton" @click.stop="onClickTipMoney(1000)">
          + {{ utils.setNumberFormat(1000) }}
        </button>
        <button class="tipButton" @click.stop="onClickTipMoney(5000)">
          + {{ utils.setNumberFormat(5000) }}
        </button>
        <button class="tipButton" @click.stop="onClickTipMoney(10000)">
          + {{ utils.setNumberFormat(10000) }}
        </button>
        <button class="tipButton" @click.stop="onClickTipMoney(50000)">
          + {{ utils.setNumberFormat(50000) }}
        </button>
        <button class="tipButton" @click.stop="onClickTipMoney(100000)">
          + {{ utils.setNumberFormat(100000) }}
        </button>
        <button class="tipButton" @click.stop="onResetTipMoney()">
          {{ t("reset") }}
        </button>
      </div>
    </div>
    <div class="tipConfirm" :class="{ mobile: utils.isMobile() }">
      <div class="dealerTip" :class="{ mobile: utils.isMobile() }">
        <div class="tipMoneyText" :class="{ mobile: utils.isMobile() }">
          {{ utils.setNumberFormat(tipMoney) }}
        </div>
        <button
          class="confirmButton"
          :class="[{ disable: !isAble }, { mobile: utils.isMobile() }]"
          @click.stop="onClickConfirmSendTip()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_358_114558)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.17082 4.51497C6.08489 3.48678 7.36448 3 8.7 3H19C21.7523 3 24 5.24772 24 8V16C24 18.7523 21.7523 21 19 21H8.60001C7.32644 21 6.07444 20.4886 5.0929 19.5071L1.69289 16.1071C0.604333 15.0185 0 13.5638 0 12C0 10.4384 0.60399 8.97142 1.81062 7.87517L5.17082 4.51497ZM8.7 5C7.84695 5 7.13701 5.30487 6.65257 5.85852C6.63796 5.87522 6.62279 5.89143 6.6071 5.90712L3.20711 9.30711C3.19471 9.31951 3.18199 9.33157 3.16896 9.3433C2.3892 10.0451 2 10.9704 2 12C2 13.0362 2.39566 13.9814 3.1071 14.6929L6.50711 18.0929C7.12556 18.7113 7.87357 19 8.60001 19H19C20.6477 19 22 17.6477 22 16V8C22 6.35228 20.6477 5 19 5H8.7Z"
                fill="#FAFAFA"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.2929 8.29289C10.6834 7.90237 11.3166 7.90237 11.7071 8.29289L17.7071 14.2929C18.0976 14.6834 18.0976 15.3166 17.7071 15.7071C17.3166 16.0976 16.6834 16.0976 16.2929 15.7071L10.2929 9.70711C9.90237 9.31658 9.90237 8.68342 10.2929 8.29289Z"
                fill="#FAFAFA"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.7071 8.29289C18.0976 8.68342 18.0976 9.31658 17.7071 9.70711L11.7071 15.7071C11.3166 16.0976 10.6834 16.0976 10.2929 15.7071C9.90237 15.3166 9.90237 14.6834 10.2929 14.2929L16.2929 8.29289C16.6834 7.90237 17.3166 7.90237 17.7071 8.29289Z"
                fill="#FAFAFA"
              />
            </g>
            <defs>
              <clipPath id="clip0_358_114558">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {{ t("sendTip") }}
        </button>
      </div>
    </div>
    <div
      v-if="modalMessage != undefined"
      class="message"
      :class="[{ err: !isAble }, { mobile: utils.isMobile() }]"
    >
      <div>{{ t(modalMessage) }}</div>
    </div>
  </div>
</template>

<script setup>
import Tab from "@/components/tab/Tab";
import { utils } from "@/utils/common";
import { useI18n } from "vue-i18n";
import { computed, ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import {
  encryptLoginData,
  encryptDataV2,
  encryptDataV1,
} from "@/utils/encrypt";
import { useRoute } from "vue-router";
import { topics, sendTopicMessage } from "@/plugins/mqtt/mqtt";
import { GAME_STATUS } from "@/const/const";

const { t } = useI18n();
const type = ref("rule");
const route = useRoute();
const store = useStore();

const modalMessage = ref(undefined);

const tipMoney = ref(0);

const typeLayout = computed(() => store.state.login.isType);
const isAble = computed(() => {
  let status =
    GAME_STATUS[
      topics?.[`${route.params.tableIdx}/live_bacc/RoomInfo`]?.status
    ] || GAME_STATUS[0];

  if (
    status == GAME_STATUS[0] ||
    status == GAME_STATUS[9] ||
    status == GAME_STATUS[10]
  )
    return true;
  else return false;
});

watch(
  () => isAble.value,
  (val) => {
    if (val == false) {
      modalMessage.value = "cantDealerTip";
    } else {
      modalMessage.value = undefined;
    }
  }
);

const loginUser = computed(() => {
  return {
    uuid: store.getters["login/getLoginUser"]?.sessionData?.account?.uuid,
    userId: store.getters["login/getLoginUser"]?.sessionData?.account?.user_id,
    balance:
      store.getters["login/getLoginUser"]?.sessionData?.goods?.amount_retained,
    currency:
      store.getters["login/getLoginUser"]?.sessionData?.account?.currency_code,
    type: store.getters["login/getLoginUser"]?.sessionData?.account
      ?.player_type,
  };
});

const onClickTipMoney = (amount) => {
  tipMoney.value += amount;
};

const onResetTipMoney = () => {
  tipMoney.value = 0;
};

const getCurrencySymbol = () => {
  if (loginUser.value.currency == "KRW") {
    return require(`@/assets/holdem/icon/won.png`);
  } else {
    if (loginUser.value.currency == undefined) {
      return require(`@/assets/holdem/icon/won.png`);
    } else {
      return require(`@/assets/holdem/icon/${loginUser.value.currency}.png`);
    }
  }
};

const dealerImage = ref(undefined);

const reqDealerImg = async () => {
  const res = await store.dispatch("request/call", {
    flag: "dealerImage",
    params: {
      start: encryptDataV2(String(0)),
      end: encryptDataV2(String(1)),
      id: encryptDataV2(
        topics?.[`${route.params.tableIdx}/live_bacc/RoomInfo`].dealer_id
      ),
      req: encryptDataV2(String(true)),
    },
  });
  if (res?.data?.images?.length > 0 && res?.data?.images[0]?.meta_png) {
    dealerImage.value = utils.decompression(res?.data?.images[0]?.meta_png);
  }
};

onMounted(async () => {
  await reqDealerImg();
});

const onClickConfirmSendTip = async () => {
  if (!isAble.value) return;
  /*
    var isConfirm = confirm( t('dealerTipConfirm') );

    if(!isConfirm)
      return;*/

  const res = await store.dispatch("request/call", {
    flag: "sendTip",
    params: {
      user_id: encryptDataV2(
        topics?.[`${route.params.tableIdx}/live_bacc/RoomInfo`].dealer_id
      ),
      amount: encryptDataV2(tipMoney.value),
      type: encryptDataV2(String(2)),
      table_no: encryptDataV2(route.params.tableIdx),
    },
    headers: {},
  });

  sendTopicMessage(`${route.params.tableIdx}/live_bacc/DealerTip`, {
    room_no: route.params.tableIdx,
    user_id: loginUser.value.userId,
    tip: tipMoney.value,
  });

  sendTopicMessage(`${route.params.tableId}/live_bacc/UserChat`, {
    room_no: route.params.tableId,
    senduserID: "NOTICE",
    chat:
      loginUser.value.userId +
      " 님께서 딜러에게 " +
      utils.setNumberFormat(tipMoney.value) +
      "의 팁을 보내셨습니다.",
    type: 0,
    dealerIdx: 0,
    dealerColor: 0,
  });

  await store.dispatch("request/call", {
    flag: "balance",
    params: { uuid: loginUser.value.uuid },
  });

  onResetTipMoney();

  modalMessage.value = t("dealerTipSuccess");

  setTimeout(() => {
    modalMessage.value = undefined;
  }, 3000);
};
</script>

<style lang="scss" scoped>
.tipWrap {
  width: 100%;
  margin: auto;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 20px;
}
.tipWrap .myMoney {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.tipWrap .tipMoney {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.tipWrap .userMoney {
  width: 60%;
  height: 60px;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    #000 51.5%,
    rgba(0, 0, 0, 0) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    top: 0;
    left: 0;
    background: linear-gradient(
      90deg,
      rgba(227, 206, 124, 0) 0%,
      #e3ce7c 49%,
      rgba(227, 206, 124, 0) 100%
    );
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      90deg,
      rgba(227, 206, 124, 0) 0%,
      #e3ce7c 49%,
      rgba(227, 206, 124, 0) 100%
    );
  }

  @media (max-width: 768px) {
    width: 90%;
  }

  @media (max-width: 500px) {
    height: max-content;
    flex-direction: column;
    padding: 10px 0;
  }
}

.tipWrap .myMonetTitle {
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fbf7b7;
  font-size: 26px;
  font-weight: 700;
  line-height: normal;

  @media (max-width: 768px) {
    font-size: 24px;
  }
}

.tipWrap .userMoneyText {
  height: 32px;
  line-height: 1.4;
  font-size: 24px;
  color: #fbf7b7;
  min-width: 200px;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px #e3ce7c;
  background: radial-gradient(
    135.92% 57.97% at 50% 50%,
    #3c3c3c 0%,
    #302a29 40%,
    #221f1d 66%,
    #27201f 100%
  );

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: inherit;
  }
}

.tipWrap .userMoneyIcon {
  position: relative;
  background-size: 100% 100%;
  left: 15px;
}

.tipButtonGroup {
  width: 96%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;

  @media (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.tipButtonGroup button {
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  border-radius: 10px;
  border: 1px solid #616161;
  background: #212121;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: 500px) {
    font-size: 15px;
  }
}

.tipConfirm {
  width: 96%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    #000 51.5%,
    rgba(0, 0, 0, 0) 100%
  );

  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    top: 0;
    left: 0;
    background: linear-gradient(
      90deg,
      rgba(227, 206, 124, 0) 0%,
      #e3ce7c 49%,
      rgba(227, 206, 124, 0) 100%
    );
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      90deg,
      rgba(227, 206, 124, 0) 0%,
      #e3ce7c 49%,
      rgba(227, 206, 124, 0) 100%
    );
  }

  @media (max-width: 500px) {
    height: max-content;
    padding: 10px 0;
  }
}

.tipConfirm .dealerTip {
  width: 100%;
  display: grid;
  grid-template-columns: calc(70% - 10px) 30%;
  align-items: center;
  grid-gap: 10px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
}
.tipConfirm .tipMoneyText {
  border-radius: 8px;
  background: #212121;
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
}

.tipWrap .confirmButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 8px 0px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 8px;
  background: linear-gradient(180deg, #256447 0%, #79ba51 100%);
}
.layoutType2 .confirmButton {
  color: #313131;
  background: linear-gradient(
    180deg,
    #f9f4e3 0%,
    #f3d36b 16.15%,
    #e8b615 85.42%,
    #614d0e 100%
  );
  svg path {
    fill: #313131;
  }
}

.tipWrap .confirmButton.disable {
  opacity: 0.5;
  cursor: not-allowed;
}

.message {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 20px 0;
  transform: translate(-50%, -50%);
  font-size: 18px;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;

  div {
    padding: 30px 100px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      top: 0;
      left: 0;
      background: linear-gradient(
        90deg,
        rgba(227, 206, 124, 0) 0%,
        #e3ce7c 49%,
        rgba(227, 206, 124, 0) 100%
      );
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        90deg,
        rgba(227, 206, 124, 0) 0%,
        #e3ce7c 49%,
        rgba(227, 206, 124, 0) 100%
      );
    }
  }
}
</style>
