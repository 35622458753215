<template>
  <router-view />
  <Toast
    @click.stop="store.commit('setErr', undefined)"
    v-if="store.getters.getErr"
    :message="store.getters.getErr"
  />
  <Modals />
</template>
<script setup>
import Modals from "@/components/modal/Modals";
import Toast from "@/components/toast/Toast.vue";
import { onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { MQTT } from "@/plugins/mqtt/mqtt";
import { encryptDataV2 } from "@/utils/encrypt";
const store = useStore();
import "@/css/common.css";
import { Buffer } from "buffer";

window.Buffer = Buffer;
window.process = require("process");

onBeforeMount(async () => {
  MQTT();
  await store.dispatch("request/call", {
    flag: "chipData",
    params: { key: encryptDataV2("chip") },
  });
});
</script>
<style>
* {
  font-family: Inter !important;
}
</style>
