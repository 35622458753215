import { reactive } from 'vue';

const modals = reactive({});
const toggleShow = (modalKey) => {
    modals[modalKey] = modals?.[modalKey] ? !modals[modalKey] : true;
}

export {
    modals,
    toggleShow,
}

