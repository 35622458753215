import store from '@/store';
import { reactive, ref } from 'vue';
import { decryptDataV2, encryptDataV2 } from '@/utils/encrypt';

const mqtt   = require("precompiled-mqtt");
const mqttOpt = {
    connectTimeout: 4000,
    reconnectPeriod: 1000,
    qos : 2,
    clean: true,
};
const mqttIpAddress = ref(undefined);

const topics = reactive({});
let client;
let isConnect = false;
const MQTT = async () => {
    const gameType = process.env.VUE_APP_GAME_TYPE;
    // let res;
    // res = await fetch('https://rt787.s3.ap-northeast-1.amazonaws.com/holdem.json', {
    //     method: "GET",
    // }).then((res) => res.text());
    //
    // let test = res.replaceAll(/^\s+|\s+$/gm,'');
    // let lastIdx = test.lastIndexOf(',');
    // let split = test.split('');
    // split.splice(lastIdx, 1);
    //
    // let serverInfo = JSON.parse(test);

    mqttIpAddress.value = 'ws://' + '54.238.15.125' + ':9001';

    if(client == null || !client.connected){
        client = mqtt.connect(mqttIpAddress.value, mqttOpt);
        client.on("connect", (topic) => {
            client.subscribe("#", mqttOpt, (err) => {
                if (!err) {
                    isConnect = true;
                }
            });
        });
        client.on("message", async (topic, message, packet) => {
            if(topic.indexOf('live_bacc') > -1){
                topics[topic] = JSON.parse(JSON.stringify(decryptDataV2(message.toString())));
            }
        });
/*
        client.on("end", (topic, message) => {
            client.end();
            client = mqtt.connect(mqttIpAddress.value);
        });
        client.on("close", (topic, message) => {
            client.end();
            client = mqtt.connect(mqttIpAddress.value);
        });
        client.on("error", (topic, message) => {
            client.end();
            client = mqtt.connect(mqttIpAddress.value);
        });
        client.on("disconnect", (topic, message) => {
            client.end();
            client = null;
            MQTT();
        });
        client.on("offline", (topic, message) => {
            client.end();
            client = null;
            MQTT();
        });
        client.on("outgoingEmpty", (topic, message) => {
            //alert('1');
            //client.end();
        });*/
    }
};

const sendTopicMessage = (topic, obj) => {
    client.publish(topic, encryptDataV2(JSON.stringify(obj)));
}

export {
    MQTT,
    topics,
    sendTopicMessage
}