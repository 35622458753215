<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <path
      opacity="0.4"
      d="M22.6665 27.8789H9.33317C5.33317 27.8789 2.6665 25.8789 2.6665 21.2122V11.8789C2.6665 7.21222 5.33317 5.21222 9.33317 5.21222H22.6665C26.6665 5.21222 29.3332 7.21222 29.3332 11.8789V21.2122C29.3332 25.8789 26.6665 27.8789 22.6665 27.8789Z"
      fill="#054589"
    />
    <path
      d="M15.9999 17.7055C14.8799 17.7055 13.7465 17.3589 12.8799 16.6522L8.70653 13.3189C8.27987 12.9722 8.19987 12.3456 8.54654 11.9189C8.8932 11.4922 9.51988 11.4122 9.94654 11.7589L14.1199 15.0922C15.1332 15.9056 16.8532 15.9056 17.8665 15.0922L22.0399 11.7589C22.4665 11.4122 23.1065 11.4789 23.4399 11.9189C23.7865 12.3456 23.7199 12.9856 23.2799 13.3189L19.1065 16.6522C18.2532 17.3589 17.1199 17.7055 15.9999 17.7055Z"
      fill="#054589"
    />
  </svg>
</template>
