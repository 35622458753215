import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { utils } from "@/utils/common";
import i18n from "./i18n";
const app = createApp(App);
import vClickOutside from "click-outside-vue3";
import BootstrapVue3 from "bootstrap-vue-3";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";

app.use(BootstrapVue3);

// 파라미터로 넘버를 받고 3자리 마다 컴마를 찍어준 후 스트링으로 반환
app.directive("numberFormat", {
  updated: (el, binding) => {
    el.innerText = utils.setNumberFormat(binding.value);
  },
  mounted: (el, binding) => {
    el.innerText = utils.setNumberFormat(binding.value);
  },
});

app.directive("moneySymbol", {
  updated: (el, binding) => {
    el.innerText = utils.getMoneySymbol(binding.value);
  },
  mounted: (el, binding) => {
    el.innerText = utils.getMoneySymbol(binding.value);
  },
});
app.config.errorHandler = (a, b, c) => {
  console.log(a, b, c);
};

app.directive("clickOutside", vClickOutside.directive);
app.use(store).use(router).use(vClickOutside).use(i18n).mount("#app");
