<template>
  <div class="loginWrap">
    <div class="wrap_content_login">
      <div class="content">Welcome to HOLDEM</div>
    </div>
    <div class="contentWrap">
      <div class="loginInfoBox">
        <div class="input-form">
          <input
            placeholder="ID"
            v-model="dataModel.userId"
            @keyup.enter="login()"
          />
          <component class="icon" :is="user2" />
        </div>
      </div>
      <div class="loginInfoBox">
        <div class="input-form">
          <input
            type="password"
            placeholder="Password"
            v-model="dataModel.pass"
            @keyup.enter="login()"
          />
          <component class="icon" :is="password2" />
        </div>
      </div>
      <div class="formWrap3">
        <button class="btn1" @click.stop="login()">
          {{ t("accountLogin") }}
        </button>
        <div class="errMsg" v-if="errMsg">
          <div>
            {{ errMsg }}
          </div>
        </div>
        <div class="wrap_action_login">
          <button
            class="btn2 btn_signUp"
            @click.stop="toggleShow('signup'), (musicType = 'click')"
          >
            {{ t("createAccount") }}
          </button>
          <button :title="t('changeSkin')" class="changeSkin" @click="handleChangeSkin(0)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              data-v-14f889e6=""
            >
              <path
                d="M13.5 9C13.5 8.70333 13.588 8.41332 13.7528 8.16665C13.9176 7.91997 14.1519 7.72771 14.426 7.61418C14.7001 7.50065 15.0017 7.47094 15.2926 7.52882C15.5836 7.5867 15.8509 7.72956 16.0607 7.93934C16.2704 8.14912 16.4133 8.41639 16.4712 8.70736C16.5291 8.99834 16.4994 9.29994 16.3858 9.57403C16.2723 9.84811 16.08 10.0824 15.8334 10.2472C15.5867 10.412 15.2967 10.5 15 10.5C14.6022 10.5 14.2206 10.342 13.9393 10.0607C13.658 9.77936 13.5 9.39782 13.5 9ZM22.125 5.25V18.75C22.125 19.2473 21.9275 19.7242 21.5758 20.0758C21.2242 20.4275 20.7473 20.625 20.25 20.625H3.75C3.25272 20.625 2.77581 20.4275 2.42417 20.0758C2.07254 19.7242 1.875 19.2473 1.875 18.75V5.25C1.875 4.75272 2.07254 4.27581 2.42417 3.92417C2.77581 3.57254 3.25272 3.375 3.75 3.375H20.25C20.7473 3.375 21.2242 3.57254 21.5758 3.92417C21.9275 4.27581 22.125 4.75272 22.125 5.25ZM4.125 5.625V13.0988L7.29938 9.92438C7.65099 9.57287 8.12782 9.3754 8.625 9.3754C9.12218 9.3754 9.59901 9.57287 9.95062 9.92438L13.8103 13.7812L15.4209 12.1716C15.7726 11.8201 16.2494 11.6226 16.7466 11.6226C17.2437 11.6226 17.7206 11.8201 18.0722 12.1716L19.875 13.9772V5.625H4.125ZM4.125 18.375H15.2194L8.625 11.7806L4.125 16.2806V18.375ZM19.875 18.375V17.1562L16.7466 14.0278L15.4012 15.375L18.4012 18.375H19.875Z"
                fill="white"
                data-v-14f889e6=""
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
  <Music :musicType="musicType" />
</template>
<script setup>
import { ref, reactive, watch } from "vue";
import { fn } from "@/views/login/login.js";
import { useI18n } from "vue-i18n";
import { toggleShow } from "@/utils/modals";
import Music from "@/components/music/Music";
import { utils } from "@/utils/common";
import { user2, password2 } from "@/assets/svg/login";
import { useStore } from "vuex";

const store = useStore();
const { t } = useI18n();
const dataModel = reactive({
  userId: "",
  pass: "",
});

const errMsg = ref(undefined);
const musicType = ref(false);

const handleChangeSkin = (status) => {
  localStorage.setItem("type", status);
  store.state.login.isType = status;
};

const login = async () => {
  musicType.value = "click";
  const res = await fn.login(dataModel);
  if (!res.data.is_success) {
    errMsg.value = t("invalidIdPass");
    /*
      if(res.data.Message.indexOf('비밀번호') > -1){
        errMsg.value = t('invalidPass');
      } else {
        errMsg.value = res.data.Message;
      }*/
    setTimeout(() => {
      errMsg.value = undefined;
    }, 1000);

    return;
  } else {
    localStorage.setItem("type", 1);
    store.state.login.isType = 1;
  }
  toggleShow("login");
};

watch(
  () => musicType.value,
  (val) => {
    if (val) {
      setTimeout(() => {
        musicType.value = undefined;
      }, 100);
    }
  }
);
</script>
<style lang="scss" scoped>
.loginWrap {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: calc(100% - 400px) auto;
  align-items: center;

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
}
.wrap_content_login {
  display: flex;
  align-items: center;
  justify-content: end;

  .content {
    color: #f4f7ee;
    text-align: center;
    text-shadow: 0px 2.5px 0px #14110c;
    -webkit-text-stroke-width: 0.7890625px;
    -webkit-text-stroke-color: #a36909;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 230px;
  }

  @media (max-width: 850px) {
    .content {
      font-size: 30px;
      width: 170px;
    }
  }

  @media (max-width: 768px) {
    .content {
      display: none;
    }
  }
}

.loginWrap .contentWrap .formWrap3 {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  align-items: center;
  margin-top: 12px;

  .wrap_action_login {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    font-size: 18px;

    .btn_signUp {
      width: calc(100% - 52px);
    }

    .changeSkin {
      font-size: 18px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 2px solid #ada270;
      background: linear-gradient(
        180deg,
        #f9f4e3 0%,
        #f3d36b 16.15%,
        #e8b615 85.42%,
        #614d0e 100%
      );
      box-shadow: 0px 0px 7.3px 0px rgba(0, 0, 0, 0.5);
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.loginWrap .contentWrap {
  max-width: 330px;
  height: max-content;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 12px;
  padding: 20px 12px;
}

.loginInfoBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.loginInfoBox .input-form {
  height: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px 10px 0px 15px;
  border-radius: 20px;
  border: 0.304px solid #fff;
  background: #fff;
  backdrop-filter: blur(6.0781121253967285px);

  @media (max-width: 768px) {
    gap: 12px;
    backdrop-filter: none;
  }
}

.loginInfoBox .input-form input {
  width: 100%;
  height: 100%;
  color: #202020;
  font-size: 18px;
}

.loginInfoBox .input-form input::placeholder {
  color: #242424;
  font-size: 16px;
}

button {
  font-size: 24px;
  letter-spacing: 0.56px;
  color: #c0a260;
  width: 100%;
  height: 40px;
  border: 1px solid transparent;
  background-clip: content-box;
  background-size: 100% 100%;
  border-radius: 20px;
}

button.btn1 {
  background: linear-gradient(180deg, #ded260 0.01%, #c1a962 100%);
  border: 1px solid #fcf6bb;
  font-size: 20px;
  color: #202020;
  transition: all 0.3s ease-in-out;

  &:hover {
    transition: all 0.3s ease-in-out;
    background: linear-gradient(
      180deg,
      #dac16e 0%,
      #f2e79b 20%,
      #fffec0 40%,
      #f0e7a1 60%,
      #dcc670 73.5%,
      #e4cf7d 100%
    );
    opacity: 1;
  }
}

button.btn2 {
  background: linear-gradient(180deg, #bdbdbd -80.61%, #353535 119.39%);
  border: 1px solid #bdbdbd;
  font-size: 20px;
  color: #f4d220;

  &:hover {
    transition: all 0.3s ease-in-out;
    opacity: 0.8;
  }
}

.errMsg {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 20px 0;
  transform: translate(-50%, -50%);
  font-size: 18px;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;

  div {
    padding: 30px 100px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      top: 0;
      left: 0;
      background: linear-gradient(
        90deg,
        rgba(227, 206, 124, 0) 0%,
        #e3ce7c 49%,
        rgba(227, 206, 124, 0) 100%
      );
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        90deg,
        rgba(227, 206, 124, 0) 0%,
        #e3ce7c 49%,
        rgba(227, 206, 124, 0) 100%
      );
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
</style>
