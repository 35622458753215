<template>
  <section id="avatar">
    <div v-for="(item, index) in 20" :key="index" class="item" @click="selectIcon(index)">
      <img
        :src="require(`@/assets/avatar/${index + 1}.png`)"
        alt="avatar"
        loading="lazy"
      />
    </div>
  </section>
</template>
<script setup>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import {computed} from "vue";
import {encryptDataV2} from "@/utils/encrypt";
import {sendTopicMessage} from "@/plugins/mqtt/mqtt";
import {toggleShow} from "@/utils/modals";
const store = useStore();
const route = useRoute();
const loginUser = computed(() => {
  return {
    uuid: store.getters["login/getLoginUser"]?.sessionData?.account?.uuid,
    userId: store.getters["login/getLoginUser"]?.sessionData?.account?.user_id,
    balance:
    store.getters["login/getLoginUser"]?.sessionData?.goods?.amount_retained,
    currency:
    store.getters["login/getLoginUser"]?.sessionData?.account?.currency_code,
    country:
    store.getters["login/getLoginUser"]?.sessionData?.account?.currency_code,
  };
});
const selectIcon = async (idx) => {
  const res = await store.dispatch("request/call", {
    flag: "changeIcon",
    params: { user_id: encryptDataV2(loginUser.value.userId), icon_idx: encryptDataV2(idx) },
  });
  if(res.data.is_success)
  {
    store.commit('login/setProfile', idx);
  }

  sendTopicMessage(`${route.params.tableIdx}/live_bacc/ChangeIcon`, {
    user_id: loginUser.value.userId,
    change_idx: idx,
  });

  toggleShow('avatar');
}
</script>
<style lang="scss" scoped>
#avatar {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;

  padding: 15px;

  height: 100%;
  overflow-y: auto;

  .item {
    height: max-content;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: 1023px) {
    height: auto;
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    height: 100%;
  }
}
</style>
