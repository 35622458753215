import { vinusAxios } from '@/plugins/axios/axios';
import { endPoints } from "@/repository/repository";
import store from '@/store';

const state = {
    callStack: []
}

const mutations = {
    setStackRequest: (state, payload) => {
        let findRequest = state.callStack.find(stack => stack.url == payload.url);
        if(findRequest){
            findRequest.error = undefined;
            findRequest.success = false;
            findRequest.pending = true;
        } else {
            state.callStack.push(payload);
        }
    },
    setStackStatus: (state, payload) => {
        let findRequest = state.callStack.find(stack => stack.url == payload.url);
        findRequest.error = undefined;
        findRequest.success = true;
        findRequest.pending = false;
    }
}

const getters = {
    getCallStack: (state) => {
        return state.callStack;
    }
}

const actions = {
    call: async ({ state }, payload = { flag: undefined, params: {}, headers: {} }) => {
        if(!payload.flag){
            return;
        }
        const defaultHeader = {
            'Content-Type': 'application/json',
        };
        if(!endPoints[payload.flag].$setting.agentKeyPass){
            defaultHeader['agent-private-key'] = store.getters['login/getAgentKey'];
        }

        const res = await vinusAxios.post(endPoints[payload.flag].url, payload.params, {
            headers: {
                ...defaultHeader,
                ...payload.headers
            },
            ...endPoints[payload.flag]?.$setting
        });

        return res;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}