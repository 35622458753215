<template>
  <section id="avatar">
    <div class="cardWrap">
      <div v-for="(item, index) in 52" :key="index" class="item" @click="selectCard(index)" :class="[{'selected' : store.getters.getSelectCardIdx == index}, {'disable' : checkCardStatus(index) == false}]">
        <img
            :src="getCardImage(index)"
            alt="avatar"
            loading="lazy"
        />
      </div>
    </div>
    <div class="buttonWrap">
      <button class="confirmButton" :class="[{'disable' : !isAble}, { 'mobile': utils.isMobile() }]" @click.stop="onClickConfirm()">
        {{ t('confirm') }}
      </button>
      <button class="cancelButton" :class="[{'disable' : !isAble}, { 'mobile': utils.isMobile() }]" @click.stop="onClickCancel()">
        {{ t('cancel') }}
      </button>
    </div>
  </section>
</template>
<script setup>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import {computed, watch} from "vue";
import { useI18n } from "vue-i18n";
import {encryptDataV2} from "@/utils/encrypt";
import {sendTopicMessage} from "@/plugins/mqtt/mqtt";
import {toggleShow} from "@/utils/modals";
import {CARD_IMG_NM_ARRAY} from "@/const/const";
import {utils} from "@/utils/common";
import { topics } from "@/plugins/mqtt/mqtt";
const { t } = useI18n();
const store = useStore();
const route = useRoute();
const loginUser = computed(() => {
  return {
    uuid: store.getters["login/getLoginUser"]?.sessionData?.account?.uuid,
    userId: store.getters["login/getLoginUser"]?.sessionData?.account?.user_id,
    balance:
    store.getters["login/getLoginUser"]?.sessionData?.goods?.amount_retained,
    currency:
    store.getters["login/getLoginUser"]?.sessionData?.account?.currency_code,
    country:
    store.getters["login/getLoginUser"]?.sessionData?.account?.currency_code,
  };
});
const selectCard = async (idx) => {
  if(checkCardStatus(idx) == true)
  {
    store.commit("setSelectCardIdx", idx);
  }
}

const onClickConfirm = async () => {
  sendTopicMessage(`${store.getters.getSelectRoomNum}/live_bacc/CardOpen`, {
    type: store.getters.getSelectCardType,
    cardSlot: store.getters.getSelectCardSlot,
    playerSlot: store.getters.getSelectPlayerSlot,
    newCardIdx: store.getters.getSelectCardIdx
  });

  toggleShow('selectCard');
  store.commit("setOnCardPopup", false);
}

const onClickCancel = () => {
  toggleShow('selectCard');
  store.commit("setOnCardPopup", false);
}

const getCardImage = (cardIdx) => {
  let cardImage = require(`@/assets/card/${CARD_IMG_NM_ARRAY[cardIdx]}.png`);
  return cardImage;
};

const checkCardStatus = (cardIdx) => {
  let response = true;

  if(topics[`${store.getters.getSelectRoomNum}/live_bacc/RoomInfo`]?.selected_card.length > 0)
  {
    topics[`${store.getters.getSelectRoomNum}/live_bacc/RoomInfo`]?.selected_card.forEach(v => {
      if(v == cardIdx)
      {
        response = false;
      }
    })
  }

  return response;
}

</script>
<style lang="scss" scoped>
.buttonWrap{
  display: flex;
  justify-content: center;
  gap: 50px;
}

.confirmButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 200px;
  padding: 8px 0px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 8px;
  background: linear-gradient(180deg, #79ba51 0%, #256447 100%);
}

.cancelButton{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 200px;
  padding: 8px 0px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 8px;
  background: linear-gradient(180deg, #ec4069 0%, #9B111E 100%);
}

#avatar{

}

.cardWrap {
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  gap: 15px;

  padding: 15px;

  height: 100%;
  overflow-y: auto;

  .item {
    height: max-content;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    &:hover {
      opacity: 0.7;
    }
  }

  .item.selected {
    background-color: #f6e54c;
    border-radius: 8px;
  }

  .item.disable{
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (max-width: 1023px) {
    height: auto;
    grid-template-columns: repeat(8, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(6, 1fr);
    height: 100%;
  }
}

@media (max-width: 768px) {
  .confirmButton, .cancelButton {
    width: 40%;
  }
  .buttonWrap {
    justify-content: space-around;
    gap: unset;
  }
}
</style>
