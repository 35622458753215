<template>
	<ul class="topBox">
		<Tab :tabs="[{tx:'deposit'},{tx:'withdrawal'}]" @clickTab="clickTab"/>
	</ul>
	<div class="listWrap">
		<div class="infoWrap">
			<div class="inputWrap" :class="{ 'mobile': utils.isMobile() }">
				<h3>{{ t('value') }}</h3>
				<div class="inputBox">
					<img src="@/assets/icon/reroll.png" @click.stop="amount = 0"/>
					<input v-model="amount" @keyup="handleQtyInput"/>
				</div>
				<div class="balance" :class="{ 'mobile': utils.isMobile() }">{{ t('balance') }} <span v-numberFormat="balance"></span></div>
			</div>
			<div class="percentBtnWrap" :class="{ 'mobile': utils.isMobile() }" :style="{ 'visibility' : type == 'deposit' ? 'hidden' : 'visible'}">
				<button @click.stop="clickPercentBtn(value)" class="percentBtn" v-for="(value, idx) in percentButtons" :key="idx"> {{ value * 100 }}%</button>
			</div>
			<div class="amountBtnWrap" :class="{ 'mobile': utils.isMobile() }">
				<button @click.stop="clickAmountBtn(value)" class="amountBtn" v-for="(value, idx) in amountButtons" :key="idx"><span v-numberFormat="value"></span></button>
			</div>
			<div class="footerWrap">
				<button @click.stop="reqTransaction(type)">{{ t(type) }}</button>
			</div>
		</div>
	</div>
</template>
<script setup>
import Tab from "@/components/tab/Tab";

import { fn } from "@/views/transaction/transaction.js";
import { utils } from "@/utils/common";
import { onBeforeMount, computed, ref } from "vue";

import { useStore } from "vuex";
const store = useStore();
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const balance = computed(() => {
	return store.getters['login/getUserBalance'];
});

const agentKey = computed(() => {
	return store.getters['login/getAgentKey'];
});

const type = ref('deposit');
const amountButtons = [1000, 5000, 10000, 50000, 100000, 500000];
const percentButtons = [0, 0.25, 0.5, 1];
const amount = ref(0);


const handleQtyInput = () => {
	amount.value = utils.setNumberFormat(amount.value);
}

const reqTransaction = async (type) => {
	const transactions = {
		deposit: fn.deposit,
		withdrawal: fn.withdrawal
	}

	let requestAmount = String(amount.value).replaceAll(',','');
	const res = await transactions[type]({ amount: requestAmount });
	if(res.data?.is_success == true){
		store.commit('setErr', `${type} request complete.: ${amount.value}`);
	}
}

const clickTab = (tab) => {
	type.value = tab.tx;
}

const clickAmountBtn = (value) => {
  let requestAmount = String(amount.value).replaceAll(',','');
  requestAmount = Number(requestAmount);
  requestAmount += value;
  amount.value = requestAmount;

  handleQtyInput();
}

const clickPercentBtn = (val) => {
  amount.value = 0;
	if(balance.value > 0){
		amount.value = balance.value * val;
	}
  handleQtyInput();
}

</script>
<style scoped>
input, button {
	width: 120px;
	height: 24px;
	background-color: white;
	color: black;
	border: 1px solid #fff;
	font-size: 14px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}
button {
	color: rgba(255, 238, 172, 1);
	border: 1px solid transparent;
	background-image: linear-gradient(to bottom, #141516, #1f2224),  linear-gradient(to top, rgba(182, 156, 74, 1), rgba(212, 190, 117, 1), rgba(255, 238, 172, 1), rgba(212, 190, 117, 1), #b69c4a), linear-gradient(to bottom, #867544, #867544);
	background-origin: border-box;
	background-clip: content-box, border-box;
}
ul.topBox{
	width: 100%;
	height: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: center;
}
.listWrap {
	width: 99%;
	margin: auto;
	padding: 24px 0;
}
.listWrap .infoWrap {
	display: flex;
	width: 100%;
	margin: 0 auto;
	flex-direction: column;
	color: #fff;
	gap: 14px;
}
.listWrap .infoWrap .percentBtnWrap {
	display: flex;
	flex-direction: row;
	height: 40px;
	gap: 10px;
	padding-bottom: 14px;
	justify-content: center;
}

.listWrap .infoWrap .percentBtnWrap button {
	width: 93px;
	height: 34px;
  font-size: 15px;
	font-weight: bold;
}

.listWrap .infoWrap .amountBtnWrap {
	display: grid;
	width: 100%;
	grid-template-columns: repeat(3, auto);
	align-items: center;
	justify-content: center;
	gap: 5px;
}

.listWrap .infoWrap .amountBtnWrap button {
	font-size: 22px;
	letter-spacing: 0.56px;
	width: 150px;
	height: 28px;
}

.listWrap .infoWrap .amountBtnWrap button span {
	font-weight: bold;
	color: rgba(255, 238, 172, 1);
	font-size: 18px;
}

.listWrap .infoWrap .percentBtnWrap.mobile button {
  width: 60px;
  height: 25px;
  font-size: 15px;
  font-weight: bold;
}

.listWrap .infoWrap .amountBtnWrap.mobile button {
  font-size: 18px;
  letter-spacing: 0.56px;
  width: 100px;
  height: 26px;
}

.listWrap .infoWrap .amountBtnWrap button span {
  font-weight: bold;
  color: rgba(255, 238, 172, 1);
  font-size: 18px;
}
.listWrap .infoWrap .inputWrap {
	width: 65%;
	margin: 0 auto;
}

.listWrap .infoWrap .inputWrap .inputBox {
	display: flex;
	align-items: center;
	height: 40px;
	position: relative;
}
.inputWrap h3{
  font-size: 14pt;
}

.inputWrap.mobile h3{
  font-size: 12pt;
}

.listWrap .infoWrap .inputWrap .inputBox img {
	position: absolute;
	cursor: pointer;
	left: 7px;
}

.listWrap .infoWrap .inputWrap .inputBox input {
	width: 100%;
	height: inherit;
	background-color: black;
	color: #ffce70;
	border: 1px solid #5d515c;
	text-align: right;
	padding-right: 7px;
}

.listWrap .infoWrap .inputWrap .balance {
	text-align: right;
	margin: 6px 0;
	font-size: 20px;
}
.listWrap .infoWrap .inputWrap.mobile .balance.mobile {
  text-align: right;
  margin: 6px 0;
  font-size: 16px;
}

.listWrap .infoWrap .footerWrap {
	display: flex;
	justify-content: center;
	margin-top: 10px;
}

.listWrap .infoWrap .footerWrap button {
	font-size: 22px;
	letter-spacing: 0.56px;
	color: rgba(255, 238, 172, 1);
	width: 288px;
	height: 48px;
	border: 1px solid transparent;
	background-image: linear-gradient(to bottom, #141516, #1f2224),  linear-gradient(to top, rgba(182, 156, 74, 1), rgba(212, 190, 117, 1), rgba(255, 238, 172, 1), rgba(212, 190, 117, 1), #b69c4a), linear-gradient(to bottom, #867544, #867544);
	background-origin: border-box;
	background-clip: content-box, border-box;
}
</style>