import axios from 'axios';
import store from "@/store";
import { endPoints } from "@/repository/repository";
const CancelToken = axios.CancelToken;

import { decryptDataV2, decryptData } from "@/utils/encrypt";

/**
 * @name: axios 기본설정
 */
const vinusAxios = axios.create({
    baseURL: process.env.VUE_APP_AXIOS_BINGO_URL,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json,  application/x-www-form-urlencoded',
        "publisher-private-key": "C105E791-F4F8-48C8-AC83-B550B3871C8A",
        "client-game-id": "C80A3C3E-99EF-4C37-819D-6E92A96AA1FA",
        "client-version-type": 1000000001,
    },
    withCredentials: true,
    transformRequest: [(data, headers) => {
        //data['agentKey'] = process.env.VUE_APP_WALLET_AGENT_KEY;
        return JSON.stringify(data);
    }]
});

const axiosErrorHandle = (error) => {
  /* const erredReq = store.getters['request/getCallStack'].find(stack => stack.url == error.config.url);
    erredReq.cancel();
   store.commit('request/setStackStatus', error.config);*/
}

vinusAxios.interceptors.request.use((req) => {
    let findRequest = store.getters['request/getCallStack'].find(stack => stack.url == req.url);
    if (findRequest && findRequest.success == false) {
        axiosErrorHandle();
    }

    new CancelToken((cancel) => {
        store.commit('request/setStackRequest', {
            url: req.url,
            cancel: cancel,
            success: false,
            error: undefined
        });
    });
    return req;
}, (error) => {
    console.error(error);
});

vinusAxios.interceptors.response.use((res) => {
   /* store.commit('request/setStackStatus', res.config);*/
    if(res.data.error > 0){
        axiosErrorHandle();
    }
    if(res.data?.result){
        let _decryptData = decryptDataV2(res.data?.result);
        res.data = _decryptData;
    }
    if(res.data?.Status !== 'ERROR' && res.config?.$useStore){
        res.config?.$useStore(res.data, res);
    }
    return res;
}, (error) => {
    return Promise.reject(error);
});

export {
    vinusAxios
}